module.exports = {
    open: open
}

function open(uibModal, ReauthorizationService, domain, showWarning) {
    var modal = uibModal.open({
        animation: true,
        backdrop: 'static',
        template: require('./modal-please-reauth-first.html'),
        size: 'xl',
        controller: [
        '$scope',
        scope => {
            scope.showWarning = showWarning;
            scope.confirmed = false;
            scope.ok = () => {
                scope.confirming = true;
                ReauthorizationService
                    .isAuthorized(domain)
                    .then((isAuthorized) => {
                            if (isAuthorized.data.approved) {
                            modal.dismiss('ok');
                            return;
                        }}, () => {
                        // we don't want to lock anyone out if isAuthorized errors
                        modal.dismiss('ok');
                        return;
                    })
                    .finally(() => {
                        scope.confirming = false;
                        scope.showWarning = true;
                    });
            };
            scope.haveReauthorized = () => {
                scope.confirmed = !scope.confirmed;
            } 
            
            scope.doNotWant = () => {
                modal.dismiss('ok');
                var ticketModal = uibModal.open({
                    animation: true,
                    backdrop: 'static',
                    template: require('./modal-please-reauth-second.html'),
                    size: 'xl',
                    controller: [
                        '$scope',
                        scope => {
                            scope.sending = false;
                            scope.whyNoReauth = '';
                            scope.goodbye = () => {
                                scope.sending = true;
                                ReauthorizationService
                                    .submitReauthTicket(domain, scope.whyNoReauth)
                                    .then(() => {
                                            scope.sending = false;
                                            ticketModal.dismiss('goodbye');
                                        },
                                        () => {
                                            scope.sending = false;
                                            ticketModal.dismiss('goodbye');
                                        }
                                    );
                            };
                            scope.goBack = () => {
                                ticketModal.dismiss('goodbye');
                                open(uibModal, ReauthorizationService, domain, true);
                            }
                        },
                    ],
                })
            }
        },
        ],
    });
}

