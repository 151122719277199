const angular = require('angular')
const _ = require('lodash')
const { waitFor } = require('../util')

module.exports = angular.module('mw.login.directive', []).directive('login', () => {
  return {
    restrict: 'E',
    template: require('./login.html'),
    controller: (
      $window,
      $scope,
      $state,
      $stateParams,
      userFactoryGoogle,
      DomainService,
      $localStorage,
      $location
    ) => {
      function onAuth() {
        const { redir } = $stateParams
        if (redir) {
          $state.go(redir, {
            domain: $stateParams.domain,
            nid: $stateParams.nid,
            isFreshLogin: true,
          })
        } else {
          var domain = DomainService.get_domain()
          var nid = DomainService.get_nid()
          // get domain & nid from cache
          if (!domain) {
            domain = _.get($localStorage, 'domain_nid.domain')
            nid = _.get($localStorage, 'domain_nid.nid')
          }
          // If you don't know the domain check again what is in the url. We found a problem when loggin in and assigning permissions to google where it looses track of this.
          if (!domain) {
            domain = $location.search().domain
          }
          // we cannot access the feature flag service here so we pass isFreshLogin for HAP-5359_HACAppReauthorize
          $state.go('monkeywrench.base.home.index', {
            domain,
            nid,
            isFreshLogin: true,
          })
        }
      }

      waitFor(() => $window.google !== undefined).then(() => {
        $window.google.accounts.id.renderButton(
          document.getElementById('googleLogInButton'),
          { theme: 'outline', size: 'large', logo_alignment: 'center' } // customization attributes
        )
      })

      angular.extend($scope, {
        login() {
          userFactoryGoogle.login().then(onAuth)
        },
      })
      // check if already logged in
      if (userFactoryGoogle.isLoggedIn()) {
        onAuth()
      }
    },
  }
}).name
