const angular = require('angular')
//const _ = require('lodash')

module.exports = angular
  .module('hac.delete-class-confirmator', [])
  .directive('hacDeleteClassConfirmator', $timeout => {
    return {
      restrict: 'E',
      scope: false,
      /**
       * RELIES ON having `deleteClass` on the scope which handles finding the class id, etc
       */
      link(scope) {
        angular.extend(scope, {
          confirm() {
            scope.confirming = true
            scope.deleteClass().finally(() => {
              scope.confirming = false
              $timeout(scope.closeStatus, 4000)
              scope.$close()
            })
          },
        })
      },
      template: require('./hac-delete-class-confirmator.html'),
    }
  }).name
