const angular = require('angular')

module.exports = angular
  .module('mw.loader', [
    require('./loader-index.directive'),
    require('./manual-config.directive'),
    require('./manual-load.directive'),
    require('./sis-config.directive'),
    require('./account-creation.directive'),
    require('./archive-classes.directive'),
    require('./delete-classes.directive'),
    require('../confirmators/hac-delete-class-confirmator.directive'),
    require('./status.directive'),
    require('./url-load.directive'),
    require('./sis-integration.directive'),
  ])
  .config($stateProvider => {
    $stateProvider
      .state('monkeywrench.base.loader', {
        url: '/loader',
        views: {
          body: {
            template: '<div ui-view><loader-index/></div>',
          },
        },
      })
      .state('monkeywrench.base.loader.index', {
        url: '/index',
        template: '<loader-index/>',
      })
      .state('monkeywrench.base.loader.status', {
        url: '/status',
        template: '<status/>',
      })
      .state('monkeywrench.base.loader.manual-config', {
        url: '/manual-config',
        template: '<manual-config/>',
      })
      .state('monkeywrench.base.loader.manual-load', {
        url: '/manual-load?type',
        template: '<manual-load/>',
      })
      .state('monkeywrench.base.loader.url-load', {
        url: '/url-load',
        template: '<url-load/>',
      })
      .state('monkeywrench.base.loader.sis-config', {
        url: '/sis-config',
        template: '<sis-config/>',
      })
      .state('monkeywrench.base.loader.sis-integration', {
        url: '/sis-integration',
        template: '<sis-integration/>',
      })
      .state('monkeywrench.base.loader.account-creation', {
        url: '/account-creation',
        template: '<account-creation/>',
      })
      .state('monkeywrench.base.loader.archive-classes', {
        url: '/archive-classes',
        template: '<archive-classes/>',
      })
      .state('monkeywrench.base.loader.delete-classes', {
        url: '/delete-classes',
        template: '<delete-classes/>',
      })
  }).name
