const angular = require('angular')
const _ = require('lodash')
module.exports = angular
  .module('mw.self-prov-groups.directive', [])
  .directive('selfProvGroups', () => {
    return {
      restrict: 'E',
      scope: true,
      controller: ($scope, $state, PrefService, Notification) => {
        $scope.prev = () => {
          $state.go('monkeywrench.self-prov.id.index')
        }
        PrefService.validate_google_group('-nj.zawadzki.co.nz')
          .then(res => {
            $scope.result = res.data.ValidationReport

            $scope.start = () => {
              $scope.syncing = true
              PrefService.sync_google_group()
                .then(() => {
                  Notification.success('Succeeded!')
                })
                .catch(resp => {
                  var msg = _.get(resp, 'data.Message')
                  Notification.error(msg || 'Failed!')
                })
                .finally(() => {
                  $scope.syncing = false
                })
            }
          })
          .catch(() => {
            $scope.error = true
          })
      },
      template: require('./groups.html'),
    }
  }).name
