const angular = require('angular')
module.exports = angular
  .module('mw.forbidden.directive', [])
  .directive('forbidden', () => {
    return {
      restrict: 'E',
      template: require('./forbidden.html'),
      controller: ($scope, $state, userFactoryGoogle) => {
        $scope.pick = function () {
          $state.go('monkeywrench.base.loader', {
            domain: $scope.domain,
          })
        }
        $scope.$watch(() => {
          return userFactoryGoogle.getUser().email
        }, (val) => {
          $scope.email = val
        })
      },
    }
  })
  .name
