const angular = require('angular')
const _ = require('lodash')

const labFeatures = { LABSHighlights: [] }

module.exports = angular
  .module('hap.experiments', [
    require('../pugs/pug-phone.service'),
    require('../shared-bus/shared-bus'),
  ])
  .factory('ExperimentsService', ($http, PugPhone, $q, haparaClientBus) => {
    let experiments = {}
    let isFeaturesLoaded = false
    let isLabPrefsLoaded = false
    let isLoadedPromise = null

    const $ES = {
      featuresLoaded() {
        if (isLoadedPromise) {
          return isLoadedPromise
        }
        //in case noone even called forUser yet
        return new Promise(resolve => {
          haparaClientBus.register(
            {
              key: 'hapara-experiments-service-ff-loaded',
              awaitEmit: true,
            },
            () => {
              resolve(true)
            }
          )
        })
      },
      /**
       * Get valid experiments for a given user
       * @param {Array} flags FIXME Remove the flags array once the pugs endpoint gets updated to no longer need it.
       * @param {String} endpoint For HAC-ng changes the endpoint to one which won't 403 for super admins. Allows the application consuming this ervice to specifiy a custom endpoint based on it's own config.
       * @param {Boolean} disablePreviewCheck Set to true in order to disable preview flag endpoint call.
       */
      forUser(flags = [], endpoint, disablePreviewCheck) {
        const deferred = $q.defer()
        let featureFlags = this.getFeatures(flags, endpoint)
        let previewFlags = this.getPreview(disablePreviewCheck)

        $q.all([featureFlags, previewFlags])
          .then(promiseResponse => {
            // Always call both endpoints but if no preview FF is present don't apply it. (only for initial testing - after that always call both)
            // if (experiments['HAP-5484_FE_Preview']) {
            let enabledPreviews = []

            if (promiseResponse[1].length) {
              // Map to PreviewFlag: status
              enabledPreviews = promiseResponse[1].map(previewFlag => {
                let enabled = {}
                enabled[previewFlag.pref] = previewFlag.enabled
                return enabled
              })
            }
            const enabledPreviewFlags = _.assign({}, ...enabledPreviews)
            for (var preview in labFeatures) {
              labFeatures[preview].forEach(ff => {
                if (!enabledPreviewFlags[preview]) {
                  experiments[ff] = false
                }
              })
            }
            _.assign(experiments, enabledPreviewFlags)
            // }
          })
          .finally(() => {
            // notify whoever is concerned that FF are ready to use
            haparaClientBus.emit('hapara-experiments-service-ff-loaded')

            // notify whoever is concerned that lab prefs are ready to use
            haparaClientBus.emit('hapara-experiments-service-lab-prefs-loaded')

            deferred.resolve(experiments)
          })
        isLoadedPromise = deferred.promise
        return deferred.promise
      },
      getFeaturesLoadStatus() {
        return isFeaturesLoaded
      },
      getFeatures(flags, endpoint) {
        const deferred = $q.defer()
        const url = endpoint || PugPhone.dial('/v2/pugs/user/flags')
        $http
          .get(url, { params: { flag: flags } })
          .then(resp => {
            _.extend(experiments, _.get(resp, 'data', {}))
          })
          .finally(() => {
            // we always resolve as successful no matter what
            isFeaturesLoaded = true
            deferred.resolve(experiments)
          })
        return deferred.promise
      },
      getLabPrefsLoadStatus() {
        return isLabPrefsLoaded
      },
      getPreview(disablePreviewCheck) {
        const deferred = $q.defer()
        const url = PugPhone.dial('/v2/pugs/user/labs_prefs')
        let previewFeatureResults = []

        if (disablePreviewCheck) {
          // Don't make the call and avoid 403 response that block entire application (HAC)
          deferred.resolve([])
          return deferred.promise
        }

        $http
          .get(url)
          .then(resp => {
            // Handled in forUser() due to dependence on other call
            // TODO: move .then() from forUser after FF is not checked anymore

            _.extend(previewFeatureResults, _.get(resp, 'data', {}))
          })
          .finally(() => {
            // we always resolve as successful no matter what
            isLabPrefsLoaded = true
            deferred.resolve(previewFeatureResults)
          })

        return deferred.promise
      },
      setPreview(flag, enable) {
        const deferred = $q.defer()
        const url = PugPhone.dial('/v2/pugs/user/labs_prefs')

        $http
          .put(url, {
            pref: flag,
            enabled: enable,
          })
          .catch(() => {
            // Add error handling
          })
          .finally(() => {
            // we always resolve as successful no matter what
            deferred.resolve()

            // TODO: post to bigQuery
          })

        return deferred.promise
      },
      hasHighlightsPreview() {
        return labFeatures['LABSHighlights'].length > 0
      },
      /**
       * Get the current value of a flag. Assumes forUser has been called.
       */
      get(experiment) {
        return !!experiments[experiment]
      },
      /**
       * Get the current value of a flag. Assumes forUser has been called. Some projects i'm looking at HAC need to use td-admin to get their FF. The payload on this end is a little different this function will deal with that over a regular get.
       */
      getTdAdmin(experiment) {
        return _.includes(experiments, experiment)
      },
      /**
       * Turn on an experiment for the current session
       */
      set(experiment) {
        experiments[experiment] = true
      },
      /**
       * Turn off an experiment for the current session
       */
      unset(experiment) {
        delete experiments[experiment]
      },
    }

    return $ES
  }).name
