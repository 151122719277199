const angular = require('angular')
module.exports = angular
  .module('mw.self-prov-id-ou.directive', [])
  .directive('selfProvIdOu', () => {
    return {
      restrict: 'E',
      scope: true,
      controller: ($scope, $state, selfProvStore) => {
        angular.extend($scope, selfProvStore)

        $scope.prev = () => {
          $state.go('monkeywrench.self-prov.id.index')
        }
        $scope.next = () => {
          $state.go('monkeywrench.self-prov.groups')
        }
      },
      template: require('./id-ou.html'),
    }
  }).name
