const angular = require('angular')
const _ = require('lodash')

module.exports = angular
  .module('mw.sis-clear-input.directive', [])
  .directive('sisClearInput', () => {
    return {
      restrict: 'A',
      scope: true,
      link (scope, element) {
        scope.clearModel = (object, param) => {
          _.set(object, param, '')
          angular.element(element.parent().parent().find('input'))[0].focus()
        }
      },
    }
  })
  .name
