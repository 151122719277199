const angular = require('angular')
const _ = require('lodash')
const CONFIG = require('../config')
const hapara_header_logo = require('hapara-assets/src/images/logo-hapara-light.svg')

module.exports = angular
  .module('mw.header.directive', [
    require('hapara-ng/src/pugs/pug-phone.service'),
  ])
  .directive('mwHeader', ($http, PugPhone, gettextCatalog) => {
    return {
      restrict: 'E',
      template: require('./header.html'),
      controller: ($scope, $state, $stateParams, $rootScope, userFactoryGoogle, DomainService) => {
        $scope.hapara_header_logo = hapara_header_logo

        $scope.td_admin_url = CONFIG.API_ROOT
        $scope.domain_nid = DomainService.get_domain_nid()
        $scope.config_page = $scope.domain_nid.indexOf('/') < 0 ? 'apps' : 'interface'

        $scope.state = $state
        $scope.$watch(
          () => userFactoryGoogle.user,
          () => {
            $scope.username = _.get(userFactoryGoogle.getUser(), 'email', 'Logging...')
          }
        )
        $scope.$watch(
          () => DomainService.get_domain(),
          () => {
            $scope.domain = DomainService.get_domain()
          }
        )

        $scope.no_classes_loaded = false

        const url = PugPhone.dial('/v2/pugs/workgroup/list/search')
        try {
          $http.get(url + `?all=true&page=0&pageSize=10`).then((response = {}) => {
            const groupsFound = _.get(response, 'data.Groups', [])
            if (groupsFound.length === 0) {
              $scope.no_classes_loaded = true
            }
          })
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err)
        }
        

        /**
         * Build URL for config
         * @config {String} page
         */
        function buildUrl(page) {
          return `${$scope.td_admin_url}/${$scope.domain_nid}?page=${page}`
        }
        /**
         * Update the list of items based on the $state parent.
         */
        function setSubnav() {
          if ($state.includes('monkeywrench.base.loader')) {
            $scope.mainNav = 'loader'
            $scope.items = [
              {
                state: 'monkeywrench.base.loader.index',
                title: gettextCatalog.getString('Overview'),
              },
              {
                state: 'monkeywrench.base.loader.status',
                title: gettextCatalog.getString('Status'),
              },
              {
                state: 'monkeywrench.base.loader.manual-config',
                title: gettextCatalog.getString('Manual Configuration'),
              },
              {
                state: 'monkeywrench.base.loader.manual-load',
                title: gettextCatalog.getString('Manual Load'),
              },
              {
                state: 'monkeywrench.base.loader.url-load',
                title: gettextCatalog.getString('URL Load'),
              },
              {
                state: 'monkeywrench.base.loader.sis-config',
                title: gettextCatalog.getString('Sync Configuration'),
              },
              {
                state: 'monkeywrench.base.loader.sis-integration',
                title: gettextCatalog.getString('Sync'),
              },
              {
                state: 'monkeywrench.base.loader.account-creation',
                title: gettextCatalog.getString('Account Creation'),
              },
              {
                state: 'monkeywrench.base.loader.archive-classes',
                title: gettextCatalog.getString('Archive Classes'),
              },
              {
                state: 'monkeywrench.base.loader.delete-classes',
                title: gettextCatalog.getString('Delete Data Loaded Classes'),
              },
            ]
          } else if ($state.includes('monkeywrench.base.configuration')) {
            $scope.mainNav = 'configuration'

            if ($stateParams.nid) {
              $scope.items = [
                {
                  state: 'monkeywrench.base.configuration.optional',
                  title: gettextCatalog.getString('Optional Settings'),
                },
              ]
            } else {
              $scope.items = [
                {
                  href: buildUrl('apps'),
                  title: gettextCatalog.getString('Google Apps Settings'),
                },
                {
                  href: buildUrl('groups'),
                  title: gettextCatalog.getString('Group Defaults'),
                },
                {
                  state: 'monkeywrench.base.configuration.optional',
                  title: gettextCatalog.getString('Optional Settings'),
                },
                {
                  href: buildUrl('schoolconfig'),
                  title: gettextCatalog.getString('School Configuration'),
                },
                {
                  href: buildUrl('auth'),
                  title: gettextCatalog.getString('Logins'),
                },
              ]
            }
          } else if ($state.includes('monkeywrench.base.analytics')) {
            $scope.items = [
              {
                state: 'monkeywrench.base.analytics',
                title: gettextCatalog.getString('Analytics'),
              },
            ]
          } else {
            $scope.mainNav = ''
            $scope.items = []
          }

          $scope.isConfig = $state.includes('monkeywrench.base.configuration')
        }

        $rootScope.$on('$stateChangeSuccess', setSubnav)
        setSubnav() // has to be set once as already changed.
      },
    }
  }).name
