const angular = require('angular')

module.exports = angular
  .module('mw.config-index', [])
  .directive('mwConfigIndex', () => {
    return {
      restrict: 'E',
      scope: false,
      template: require('./config-index.html'),
    }
  }).name
