require('babel-polyfill')
const angular = require('angular')
const typeahead = require('angular-ui-bootstrap/src/typeahead/index-nocss.js')
const modal = require('angular-ui-bootstrap/src/modal/index-nocss.js')
const { version, env } = require('./config')
const _ = require('lodash')

require('../assets/favicon.png')
require('../assets/favicon.ico')
require('../assets/css/close.svg')

require('font-awesome-webpack')
require('./index.scss')
require('angular-gettext')
require('angulartics')
require('moment')
require('angular-moment')
require('angular-gapi')
require('angular-clipboard')
require('angular-ui-notification')
require('angular-ui-bootstrap')

require('ngstorage')
require('ng-tags-input')

require('hapara-ng/src/environments/environment')
require('hapara-ng/src/gainsight/gainsight')

angular
  .module('monkeywrench-ng', [
    require('angular-animate'),
    require('angular-resource'),
    require('angular-sanitize'),
    require('angular-scroll'),
    typeahead,
    modal,
    'angulartics',
    'angularMoment',
    'ngStorage',
    'angular-clipboard',
    'ui-notification',
    require('angulartics-google-analytics'),
    require('./base-states'),
    require('./google'),
    require('./http'),
    require('./shared'),
    require('./service'),
    require('./base.controller'),
    require('./header'),
    require('./user'),
    require('./configuration'),
    require('./home'),
    require('./domain-audit'),
    require('./errors'),
    require('./loader'),
    require('./analytics'),
    require('./self-prov'),
    require('./usage-report'),
  ])
  // export MONKEYWRENCH_VERSION
  .config($windowProvider => {
    $windowProvider.$get().MONKEYWRENCH_VERSION = version
  })
  .constant('env', env)
  .config($animateProvider => {
    // Let ngAnimate know which elements to not handle
    $animateProvider.classNameFilter(/^((?!(fa-spin|repeat-modify)).)*$/)
  })
  .config((env, $analyticsProvider, $localStorageProvider, NotificationProvider) => {
    const analytics = env === 'production' || window.location.hash.indexOf('analytics=true') >= 0

    $analyticsProvider.developerMode(!analytics)

    $localStorageProvider.setKeyPrefix('mw_')

    NotificationProvider.setOptions({
      delay: 5000,
      startTop: 30,
      positionX: 'center',
      positionY: 'top',
    })
  })
  .config((env, haparaEnvProvider) => {
    let url

    if (env === 'production') {
      url = 'https://haparaview.appspot.com/'
    } else if (env === 'internal') {
      url = 'https://hydra-internal-dot-haparaview.appspot.com/'
    } else if (env === 'patch') {
      url = 'https://hydra-patch-dot-haparaview.appspot.com/'
    } else if (env == 'local') {
      url = 'http://localhost:8082/'
    } else {
      url = 'https://hydra-test-dot-haparaview.appspot.com/'
    }
    haparaEnvProvider.groupSet('shared').set('tdoServerUrl', url)
  })
  .run($rootScope => {
    // Look for Grey background on route and apply that class to body for css to do the rest.
    $rootScope.$on('$stateChangeSuccess', function (event, toState) {
      $rootScope.greyBackground = _.get(toState, 'data.greyBackGround')
    })
  })
