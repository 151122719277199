const angular = require('angular')
const _ = require('lodash')

require('angular-ui-bootstrap/src/tooltip/index-nocss')

module.exports = angular
  .module('mw.config-optional', [
    'gettext',
    'ui.bootstrap.module.tooltip',
    require('angular-ui-router'),
    require('./config-index.directive'),
    require('./config.service'),
    require('./allclasses-config.directive'),
    require('./classes-config.directive'),
    require('./documents-config.directive'),
    require('./email-config.directive'),
    require('./students-config.directive'),
    require('./domain-group-verification.directive'),
    require('./school-groups.directive'),
    require('./timezone-config.directive'),
    require('angular-ui-notification'),
  ])
  .directive(
    'mwConfigOptional',
    (gettextCatalog, $state, $stateParams, $rootScope, MwConfigService, Notification) => {
      return {
        restrict: 'E',
        scope: true,
        link(scope) {
          angular.extend(scope, {
            /**
             * Holder for optional config data
             */
            config: {
              loaded: false,
              loading: false,
            },
            /**
             * Set categories based on the supplied config
             * @param {Object} config
             */
            setCategories(config) {
              const canCreate = _.get(config, 'AvailableActions.SetupCreateClass')
              let classLabel
              if (canCreate) {
                classLabel = gettextCatalog.getString(
                  `Control teacher's ability to create and modify classes and class rosters.`
                )
              } else {
                classLabel = gettextCatalog.getString(
                  `Control teacher's ability to modify class rosters.`
                )
              }
              scope.categories = [
                {
                  description: gettextCatalog.getString(
                    'Set the timezone for your domain or school.'
                  ),
                  label: gettextCatalog.getString('Domain Timezone'),
                  link: '.timezone',
                },
                {
                  description: gettextCatalog.getString(
                    'Control the document sharing and visibility for teachers in your domains or schools using Hapara tools.'
                  ),
                  label: gettextCatalog.getString('Document Management'),
                  link: '.documents',
                },
                {
                  description: classLabel,
                  label: gettextCatalog.getString('Class Management'),
                  link: '.classes',
                },
                {
                  description: gettextCatalog.getString(
                    `Control teacher's ability to reset student passwords.`
                  ),
                  label: gettextCatalog.getString('Student Management'),
                  link: '.students',
                },
                {
                  description: gettextCatalog.getString(
                    'Control what happens when email links are clicked.'
                  ),
                  label: gettextCatalog.getString('Email Settings'),
                  link: '.email',
                },
                {
                  description: gettextCatalog.getString(
                    'Control which classes teachers can see in your domains or schools.'
                  ),
                  label: gettextCatalog.getString('Class Settings'),
                  link: '.allclasses',
                },
              ]
            },
            /**
             * @config {Array} categories of optional config
             */
            categories: [],
            applyConfig(response = {}) {
              const config = _.cloneDeep(response)
              config.AvailableActions = _.zipObject(
                config.AvailableActions,
                config.AvailableActions
              )

              config.Prefs = angular.extend(config.Prefs, {
                landing_module: _.get(config, 'Prefs.landing_module') || 'dashboard',
              })
              scope.setCategories(config)

              scope.config = config
            },
            /**
             * Fetch config data
             */
            fetch() {
              scope.config.loading = true
              MwConfigService.fetch({
                domain: $stateParams.domain,
                nid: $stateParams.nid,
              })
                .then(scope.applyConfig)
                .catch(err => {
                  Notification.error(err.msg)
                })
                .finally(() => {
                  scope.config.loading = false
                  scope.config.loaded = true
                })
            },
            /**
             * @config {Object} state
             * @config {Integer} state.category
             */
            state: {
              category: undefined,
            },
            /**
             * Update the config. Filter the config.Prefs by the field names passed.
             * @param {String...} fields
             */
            save(...fields) {
              scope.config.updating = true
              const settings = _.pick(scope.config.Prefs, ...fields)

              return MwConfigService.set({
                domain: $stateParams.domain,
                nid: $stateParams.nid,
                settings,
              })
                .catch(e => {
                  scope.config.errored = e
                })
                .finally(() => {
                  scope.config.updating = false
                })
            },
          })

          function onStateChange() {
            scope.show_submenu = $state.current.name !== 'monkeywrench.base.configuration.optional'
          }
          const unsubscribeStateChange = $rootScope.$on('$stateChangeSuccess', onStateChange)
          scope.$on('$destroy', unsubscribeStateChange)
          onStateChange() // run at least once

          scope.fetch()
        },
        template: require('./config-optional.html'),
      }
    }
  ).name
