const angular = require('angular')
const _ = require('lodash')

module.exports = angular
  .module('mw.domain-group-verification', [
    'gettext',
    require('angular-ui-router'),
    require('./config.service'),
  ])
  .directive(
    'mwDomainGroupVerification',
    (gettextCatalog, $stateParams, $timeout, MwConfigService) => {
      return {
        restrict: 'E',
        scope: true,
        link(scope) {
          angular.extend(scope, {
            domainState: {
              error: undefined,
              isVerified: false,
              potentialGroup: '',
              potentialUsers: undefined,
              verifying: false,
            },
            error: undefined,
            /**
             * Controls if we should enable or disable the verify button
             */
            /**
             * Clears the group state
             */
            clearGroup() {
              _.extend(scope.domainState, {
                error: undefined,
                isVerified: false,
                potentialGroup: '',
                potentialUsers: undefined,
              })
              scope.validateForm()
            },
            /**
             * Set the form valid based on whether the `IsDailyScanPotentialWorkGroupManagers` is true
             * or if there are valid sample users from the suggested group
             */
            validateForm() {
              const hasUsers = _.get(scope, 'domainState.potentialUsers.length')
              const valid = !!(hasUsers || scope.domainState.isVerified)

              scope.group_form.$setValidity('confirmedGroup', valid)
            },
            /**
             * Dispatch the VALIDATE_GROUP event. This will get the list of users.
             */
            verify() {
              const googleGroupId = scope.domainState.potentialGroup
              const { domain } = $stateParams
              if (scope.isDuplicate(googleGroupId)) {
                scope.domainState.error = gettextCatalog.getString(
                  `Google Groups have to be unique.`
                )
                return
              }
              scope.domainState.verifying = true
              scope.domainState.error = undefined
              MwConfigService.validate({ domain, googleGroupId })
                .then((res = {}) => {
                  scope.domainState.potentialUsers = _.get(
                    res,
                    'ValidationReport.SampleUsers',
                    []
                  )
                  _.set(
                    scope,
                    'config.UserValidationInfo.PotentialManagersGroupURN',
                    googleGroupId
                  )

                  scope.validateForm()
                })
                .catch(e => {
                  scope.domainState.error = _.get(e, 'data.Message')
                })
                .finally(() => {
                  scope.domainState.verifying = false
                })
            },
            updateState() {
              angular.extend(scope.domainState, {
                isVerified: _.get(
                  scope,
                  'config.UserValidationInfo.IsDailyScanPotentialWorkGroupManagers'
                ),
                potentialGroup: _.get(
                  scope,
                  'config.UserValidationInfo.PotentialManagersGroupURN'
                ),
                potentialUsers: undefined,
              })
              scope.validateForm()
            },
          })

          scope.$watch('config', scope.updateState)
        },
        template: require('./domain-group-verification.html'),
      }
    }
  ).name
