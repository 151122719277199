const angular = require('angular')
const _ = require('lodash')

module.exports = angular
  .module('hap.tdo-server', [require('../environments/environment')])
  .factory('tdoServer', haparaEnv => {

    return function (url) {
      if (_.startsWith(url, haparaEnv.shared.tdoServerUrl)) {
        return url
      }
      return haparaEnv.shared.tdoServerUrl + url
    }
  }).name
