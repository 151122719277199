const angular = require('angular')
const _ = require('lodash')
const { map } = require('../optionsmap')
const { validate, validate_and_flatten, concat } = require('../util')

module.exports = angular
  .module('mw.manual-config.directive', [
    'gettext',
    require('./confirm-dialog.service'),
  ])
  .directive(
    'manualConfig',
    ($rootScope, $window, gettextCatalog, confirmDialog) => {
      return {
        restrict: 'E',
        template: require('./manual-config.html'),
        link: (scope, element) => {
          scope.element = element[0]

          $window.onbeforeunload = event => {
            event = event || $window.event || {}

            const dirty = _.get(scope, 'form.$dirty')

            if (dirty) {
              event.returnValue = gettextCatalog.getString(
                'Your changes will be lost if you navigate away from this page without saving. Continue?'
              )
              return event.returnValue
            }
          }

          const unsubscribe = $rootScope.$on('$stateChangeStart', e => {
            const dirty = _.get(scope, 'form.$dirty')

            if (dirty) {
              const leave = confirmDialog.confirm(
                gettextCatalog.getString(
                  'Your changes will be lost if you navigate away from this page without saving. Continue?'
                )
              )

              if (!leave) {
                e.preventDefault()
              }
            }
          })

          scope.$on('$destroy', () => {
            $window.onbeforeunload = undefined
            unsubscribe()
          })
        },
        controller: (
          $scope,
          $state,
          $stateParams,
          $rootScope,
          $timeout,
          PrefService
        ) => {

          $scope.wizard = {
            step_cur_index: -1,
            title: 'Manual Configuration',
            steps: [
              'File Type',
              'Student Folders',
              'Calendars',
              'Student Gmails',
              'Configuration Files',
            ],
          }

          const get_sample_base_link = () => {
            $scope.sample_base_link = PrefService.get_sample_base_link()
            if (
              $scope.data.prov_man_doc_type.value ===
              $scope.data.prov_man_doc_type.options[2]
            ) {
              $scope.sample_base_link += '&format=csv'
            }
          }

          PrefService.get('prov_man').then(function(resp) {
            $scope.load_year_suffix_old = resp.data.load_year_suffix.value
            $scope.data = validate(resp.data)
            $scope.data.prov_man_doc_type.value = 'manual_load' // Set default setting for checkbox
            $scope.data.prov_man_folder_names.values = _.map(
              $scope.data.prov_man_folder_names.value.split(','),
              e => ({
                text: e,
              })
            )

            if (!$scope.data.ui_enable_gmail.value) {
              _.pull($scope.wizard.steps, 'Student Gmails')
            }

            $scope.wizard.step_total = $scope.wizard.steps.length
            $scope.wizard.step_prev = () => {
              $scope.wizard.step_cur_index =
                --$scope.wizard.step_cur_index % $scope.wizard.step_total
            }
            $scope.wizard.step_next = () => {
              $scope.wizard.step_cur_index =
                ++$scope.wizard.step_cur_index % $scope.wizard.step_total
            }
            $scope.wizard.step_finish = () => {
              $state.go('monkeywrench.base.loader.index')
            }

            $scope.isCurrentStepCalendars = function(type) {
              if (!type) {
                return false
              }
              return type === 'Calendars'
            }

            get_sample_base_link()

            $scope.wizard.step_resolving = false
            $scope.wizard.step_save = () => {
              if ($scope.wizard.step_resolving) return
              $scope.wizard.step_resolving = true
              $scope.data.prov_man_folder_names.value = concat(
                _.map($scope.data.prov_man_folder_names.values, 'text')
              )

              var form = validate_and_flatten($scope.data)

              PrefService.save('prov_man', form)
                .then(function() {
                  $scope.wizard.step_resolving = false

                  get_sample_base_link()

                  $scope.wizard.step_next()
                  $scope.form.$setPristine()
                })
                .catch(function() {
                  $scope.wizard.step_resolving = false
                })
            }
            // folders
            $scope.folders = {
              folder_add: () => {
                $scope.data.prov_man_folder_names.values.push({
                  text: '',
                })
                $timeout(() => {
                  var inputs = $scope.element.querySelectorAll(
                    '.editable-list input'
                  )
                  inputs[inputs.length - 1].focus()
                })
              },
              folder_delete: f => {
                _.pull($scope.data.prov_man_folder_names.values, f)
              },
            }

            $scope.$watch(
              () => {
                // must specify at least one folder name if option 4 is selected
                return (
                  $scope.data.prov_man_folder_option.value !== 
                    map.prov_man_folder_option_enumerated ||
                  _.trim(
                    _.map(
                      $scope.data.prov_man_folder_names.values,
                      'text'
                    ).join('')
                  ) !== ''
                )
              },
              val => {
                $scope.folders.error = !val
              }
            )
          })
        },
      }
    }
  ).name
