const angular = require('angular')
const _ = require('lodash')
const { validate_and_flatten } = require('../util')
module.exports = angular
  .module('mw.archive-classes.directive', [])
  .directive('archiveClasses', () => {
    return {
      restrict: 'E',
      template: require('./archive-classes.html'),
      controller: (
        $scope,
        $rootScope,
        $state,
        $stateParams,
        $http,
        $uibModal,
        PrefService
      ) => {
        $scope.archiveall = 'true'
        $scope.cancel = () => {
          $state.go('monkeywrench.base.loader.index')
        }

        PrefService.get('archiveload').then(function(resp) {
          $scope.data = resp.data
          $scope.onchange = () => {
            // everytime the spreadsheet is changed, it needs to re verify
            _.set($scope, 'data.docid.value', '')
            $scope.verify_doc_failed = false
          }
          $scope.verify_doc = () => {
            var doc_name = $scope.data.spreadsheet_archivename.value
            PrefService.verify_doc(doc_name).then(resp => {
              $scope.data = resp.data
              if (!_.get($scope, 'data.docid.value')) {
                $scope.verify_doc_failed = true
              }
            })
          }
          $scope.find_doc = doc_name => {
            return PrefService.find_doc(doc_name).then(function(response) {
              return _.isArray(response.data) ? response.data : []
            })
          }
          $scope.resolving = false
          $scope.archive = () => {
            if ($scope.resolving) return

            var modalInstance = $uibModal.open({
              animation: true,
              template: require('../modal/archiving.html'),
              size: 'md',
              controller: [
                '$scope',
                scope => {
                  scope.ok = function() {
                    $scope.resolving = true
                    var form = validate_and_flatten($scope.data)
                    PrefService.archive(form)
                      .then(() => {
                        $state.go('monkeywrench.base.loader.status')
                      })
                      .finally(() => {
                        $scope.resolving = false
                      })
                    modalInstance.close()
                  }
                  scope.cancel = function() {
                    modalInstance.dismiss('cancel')
                  }
                },
              ],
            })
          }
          $scope.archive_all = () => {
            if ($scope.resolving) return
            var modalInstance = $uibModal.open({
              animation: true,
              template: require('../modal/archiving.html'),
              size: 'md',
              controller: [
                '$scope',
                scope => {
                  scope.ok = function() {
                    $scope.resolving = true
                    var form = validate_and_flatten($scope.data)
                    PrefService.archive_all(form)
                      .then(() => {
                        $state.go('monkeywrench.base.loader.status')
                      })
                      .finally(() => {
                        $scope.resolving = false
                      })
                    modalInstance.close()
                  }
                  scope.cancel = function() {
                    modalInstance.dismiss('cancel')
                  }
                },
              ],
            })
          }
        })
      },
    }
  }).name
