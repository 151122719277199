const angular = require('angular')
const _ = require('lodash')

module.exports = angular
  .module('ws.error-reporter', [])
  .factory('ErrorReporter', ($injector, $log, env, Raygun) => {
    const $ER = {
      /**
       * Report uncaught exceptions depending on environment.
       *
       * @param {ErrorObject} e
       */
      report(e) {
        if (env === 'unittest') {
          throw e
        }

        // if there's a cache on the $rootScope, add this Error to the 'uncaught' key
        const root = $injector.get('$rootScope')
        const cache = _.get(root, 'errorCache', {})
        cache.uncaught = e

        if (env !== 'production') {
          $log.error(e)
        } else {
          Raygun.send(e, null, ['crash', 'frontend', env])
        }
      },
    }

    return $ER
  }).name
