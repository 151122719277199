const angular = require('angular')
const _ = require('lodash')

module.exports = angular
  .module('mw.school-groups', [
    require('./school-group.directive'),
  ])
  .directive('mwSchoolGroups', (
    $stateParams,
  ) => {
    return {
      restrict: 'E',
      scope: false,
      link (scope) {
        angular.extend(scope, {
          showWarning: true,
          checkSitesValidity () {
            // only do this if at domain level
            if ($stateParams.nid) {
              scope.showWarning = false
              return
            }

            const sites = _.get(scope, 'config.UserValidationInfo.SitesSetupInfo')

            const valid = _.some(sites, (site) => {
              return site.PotentialManagersGroupURN
            })

            scope.groups_form.$setValidity('allGroups', valid)
          },
        })

        scope.checkSitesValidity()
      },
      template: require('./school-groups.html'),
    }
  })
  .name
