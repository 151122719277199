const angular = require('angular')
const jose = require('jose')
const _ = require('lodash')
const { getToken, initHaparaJWT, invalidateRefreshToken, deleteJWT } = require('./jwtHandler')

require('angular-gettext')
require('angular-ui-router')

const TEST_ENV = 'test'
const PROD_ENV = 'production'
const DEVELOPMENT_ENV = 'development'
const getCurrentEnv = () => {
  const host = window.location.hostname || '' // eslint-disable-line angular/window-service
  if (host.match(/-test/)) {
    return TEST_ENV
  } else if (host.match(/localhost/)) {
    return DEVELOPMENT_ENV
  }
  return PROD_ENV
}

const isProduction = () => {
  return getCurrentEnv() === PROD_ENV
}

module.exports = angular
  .module('mw.google', ['gettext', require('angular-ui-router')])
  .run(($window, $log, userFactoryGoogle) => {
    const js = document.createElement('script') // eslint-disable-line angular/document-service
    js.src = 'https://accounts.google.com/gsi/client'
    js.setAttribute('async', '')
    js.setAttribute('defer', '')
    js.onerror = err => {
      userFactoryGoogle.gapiError = err
      userFactoryGoogle.onSignInChange(false)
    }
    js.onload = async () => {
      try {
        $window.google.accounts.id.initialize({
          client_id: !isProduction()
            ? '283337183316-ric24sm1pf4j5bkiv4ev79g63vnqs9la.apps.googleusercontent.com'
            : '283337183316.apps.googleusercontent.com',
          callback: async tokenResponse => {
            const userAuthData = jose.decodeJwt(tokenResponse.credential)

            await initHaparaJWT(tokenResponse.credential)
            const user = {
              email: _.get(userAuthData, 'email'),
              name: _.get(userAuthData, 'name'),
              given_name: _.get(userAuthData, 'given_name'),
              family_name: _.get(userAuthData, 'family_name'),
              image_url: _.get(userAuthData, 'picture'),
              id: _.get(userAuthData, 'sub'),
            }

            localStorage.setItem('userProfile', JSON.stringify(user))

            userFactoryGoogle.gapiInjection.resolve()
            userFactoryGoogle.onSignInChange(true)
            $log.info(`Successfully initialised gapi client`)

            userFactoryGoogle.loginNew()
          },
        })
      } catch (err) {
        userFactoryGoogle.gapiInjection.reject(err)
        $log.error(
          `Failed to initialise GIS ${err}` // eslint-disable-line angular/json-functions
        )
      }
    }

    angular
      .element(document.head) // eslint-disable-line angular/document-service
      .append(js)
  })
  .factory(
    'userFactoryGoogle',
    (
      $log,
      $timeout,
      $location,
      $state,
      $stateParams,
      $q,
      $window,
      gettextCatalog,
      $localStorage
    ) => {
      const $US = {
        getUser() {
          if ($localStorage.testUser) {
            const user = {
              email: $localStorage.testUser.email,
              name: 'Test User',
              given_name: 'Test',
              family_name: 'User',
              image_url:
                'https://lh6.googleusercontent.com/-rG0GWxtSh4w/AAAAAAAAAAI/AAAAAAAAAAA/ACHi3rdNtV2NUDC3rB8KOVA66mv1EoD6FA/s96-c/photo.jpg',
            }
            return user
          } else {
            const user = {}
            try {
              const profile = JSON.parse(localStorage.getItem('userProfile'))
              user.email = profile.email
              user.name = profile.name
              user.given_name = profile.given_name
              user.family_name = profile.family_name
              user.image_url = profile.image_url
              user.id = profile.id
              return user
            } catch (e) {
              return undefined
            }
          }
        },
        gapiError: undefined,
        gapiInjection: $q.defer(),

        onSignInChange(signedIn) {
          if (!signedIn) {
            if ($localStorage.testUser) {
              $log.info('Test user detected')
            } else if ($location.path().indexOf('test-login') < 0) {
              $timeout(() => {
                $US.setRedirectTo($window.location.href)
                try {
                  $state.go('anon.login', $stateParams)
                } catch (err) {
                  $log.info('Error', err)
                }
              })
            }
          }
        },
        async getAuth() {
          if ($localStorage.testUser) {
            return {
              token: $localStorage.testToken,
              userEmail: $US.getUser().email,
            }
          }
          try {
            const token = await getToken()
            if (!token) {
              $log.info(`Couldn't get token from client for header`)
            } else {
              return {
                token: token.access_token,
                userEmail: $US.getUser().email,
              }
            }
          } catch (err) {
            return {
              token: null,
              userEmail: null,
            }
          }
        },
        isLoggedIn() {
          if ($localStorage.testUser) {
            return true
          }
          const userProfile = JSON.parse(localStorage.getItem('userProfile')) || {}
          const expires_at = JSON.parse(localStorage.getItem('tokenExpire'))
          const isTokenValid = expires_at && expires_at > Date.now()
          return userProfile.email && isTokenValid
        },
        testLogin(email, token) {
          const expires_at = Date.now() + 30 * 60 * 1000 // 30 mins
          const user = {
            email: email,
            name: 'Test User',
            given_name: 'Test',
            family_name: 'User',
            image_url:
              'https://lh6.googleusercontent.com/-rG0GWxtSh4w/AAAAAAAAAAI/AAAAAAAAAAA/ACHi3rdNtV2NUDC3rB8KOVA66mv1EoD6FA/s96-c/photo.jpg',
            id: '11111',
          }
          localStorage.setItem('userProfile', JSON.stringify(user))
          localStorage.setItem('tokenExpire', expires_at)
          localStorage.setItem('testUser', user)
          localStorage.setItem('testToken', token)

          initHaparaJWT(token)
        },
        loginNew() {
          $window.location = $US.getRedirectTo()
        },
        login() {
          $window.location = $US.getRedirectTo()
        },
        setRedirectTo(url) {
          $US.redirectTo = url
        },
        getRedirectTo() {
          const { host, protocol } = $window.location
          if (!$US.redirectTo || $US.redirectTo.indexOf('login') !== -1)
            return `${protocol}//${host}`
          return $US.redirectTo
        },
        logout() {
          try {
            invalidateRefreshToken()
            deleteJWT()
          } catch (e) {
            // console.debug('log out error', e)
          }
          localStorage.removeItem('userProfile')
          localStorage.removeItem('tokenExpire')
          localStorage.removeItem('testUser')
          localStorage.removeItem('testToken')
          $window.location = $state.href('anon.login')
          $window.location.reload(true)
        },
      }

      return $US
    }
  ).name
