const _ = require('lodash')

const validate = data => {
  data = _.cloneDeep(data)
  _.each(data, v => {
    if ('options' in v) {
      if (_.indexOf(v.options, v.value) === -1) {
        v.value = v.options[0]
      }
    }
  })
  return data
}
const flatten = data => {
  return _.reduce(
    data,
    (ret, v, k) => {
      ret[k] = v.value
      return ret
    },
    {}
  )
}

const validate_and_flatten = data => {
  return flatten(validate(data))
}
const concat = arr => {
  return _.uniq(_.filter(_.map(arr, _.trim))).join(',')
}

const formalizeOU = str => {
  if (str.length > 0 && str[0] !== '/') {
    return '/' + str
  }
  return str
}

const waitFor = predicate => {
  const timeout = 5000
  const interval = 100
  return new Promise((resolve, reject) => {
    let elapsed = 0

    const timer = setInterval(() => {
      elapsed += interval
      if (predicate()) {
        clearInterval(timer)
        resolve()
      }
      if (elapsed >= timeout) {
        clearInterval(timer)
        reject()
      }
    }, interval)
  })
}

module.exports = {
  validate,
  flatten,
  validate_and_flatten,
  concat,
  formalizeOU,
  waitFor,
}
