const angular = require('angular')
const sha256 = require('hash.js/lib/hash/sha/256')
const _ = require('lodash')

module.exports = angular
  .module('hap.support-includes', [
    require('../pugs/pug-phone.service'),
    require('../experiments/experiments.service'),
  ])
  .factory(
    'SupportService',
    ($http, $document, $window, PugPhone, userFactoryGoogle, $log, ExperimentsService) => {
      let initialised = false

      // Formats student's data for Pendo
      function formatStudentForPendo(user, resp) {
        const { id, email, role } = user
        let disableFeedback = true
        const pendoId =
          id ||
          sha256()
            .update(email)
            .digest('hex')

        const visitor = {
          id: pendoId,
          role,
        }

        const data = resp.data || {}
        const schools = _.get(data, 'Schools', [])
        if (!_.isEmpty(schools)) {
          visitor.school_code = schools[0].Code
          visitor.school_name = schools[0].Name
        }

        return {
          visitor,
          account: { id: user.email.split('@')[1] },
          disableFeedback,
        }
      }

      // Formats user features data for Pendo
      function formatForPendo(id, email, response) {
        const data = response.data || {}
        const visitor = {
          id: id || data.MasqUser || data.ID,
          email: _.toLower(email),
        }

        const schools = _.get(data, 'Schools', [])
        if (!_.isEmpty(schools)) {
          visitor.school_code = schools[0].Code
          visitor.school_name = schools[0].Name
        }

        let roleKeys = Object.keys(data.Roles)

        roleKeys.forEach(key => {
          visitor[`role_${key}`] = data.Roles[key]
        })

        visitor.name = `${data.FirstName} ${data.LastName}`
        visitor.firstName = data.FirstName
        visitor.lastName = data.LastName

        const classTypesT = Object.keys(data.ClassTypesTaught)
        classTypesT.forEach(key => {
          visitor[`teaches_${key}`] = data.ClassTypesTaught[key]
        })

        const classTypesA = Object.keys(data.ClassTypesAvailable)
        classTypesA.forEach(key => {
          visitor[`mayTeach_${key}`] = data.ClassTypesAvailable[key]
        })

        const account = {
          id: data.Domains[0],
        }

        const featureKeys = Object.keys(data.Features)
        featureKeys.forEach(key => {
          account[`has_feature_${key}`] = data.Features[key]
        })

        // TODO - add ClassTypesAvailable to account data?  Works best if accounts done at school level
        return { visitor, account }
      }

      let userFeaturesPromise = null
      function getUserFeatures() {
        if (!userFeaturesPromise) {
          const url = PugPhone.dial('/v2/pugs/user/features')
          userFeaturesPromise = $http.get(url)
        }
        return userFeaturesPromise
      }

      function attachPendo(user) {
        const { id, email } = user
        const doc = $document[0]
        const isStudent = user.role === 'student'
        const apiKey = isStudent
          ? `'b9f27824-99f9-4887-54bf-3980b4fdd496'` // Pendo key for students
          : `'f900cd67-3165-4eb2-4356-d7b998ff3184'`
        const script = doc.createElement('script')
        script.type = 'text/javascript'
        script.text = `(function(apiKey){
          (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=[];
          v=['initialize','identify','updateOptions','pageLoad'];for(w=0,x=v.length;w<x;++w)(function(m){
              o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
              y=e.createElement(n);y.async=!0;y.src='https://content.hsuite.activity.hapara.com/agent/static/'+apiKey+'/pendo.js';
              z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
          })(${apiKey});`
        doc.head.appendChild(script)

        if (isStudent) {
          getUserFeatures().then((resp = {}) =>
            pendo.initialize(formatStudentForPendo(user, resp))
          )
        } else {
          getUserFeatures()
            .then((resp = {}) => formatForPendo(id, email, resp))
            .then(data => {
              data.visitor.id = user.id
              pendo.initialize(data)
            })
        }
      }

      function attachZendesk() {
        const doc = $document[0]
        const script = doc.createElement('script')
        script.type = 'text/javascript'
        script.id = 'ze-snippet'
        script.src =
          'https://static.zdassets.com/ekr/snippet.js?key=25d3aad9-95dd-4d93-a621-3420b8aea33a'
        doc.head.appendChild(script)
      }

      return {
        /**
         * Hook in zendesk and pendo. Only runs once.
         */
        getUserFeatures: () => getUserFeatures(),

        /**
         * Hook in Zendesk and Pendo. Only runs once.
         */
        init(isStudent = false) {
          if ($window.location.hash.indexOf('analytics=false') >= 0) {
            // if hash includes `analytics=false` force `initialised` to true so it doesn't run
            initialised = true
          }
          if (initialised) {
            return
          }
          if (__NODE_ENV__ === 'test') {
            // no support scripts for tests
            return
          }

          const user = userFactoryGoogle.user || userFactoryGoogle.getUser() || {}

          if (isStudent) {
            user.role = 'student'
          }

          attachPendo(user)
          if ($window.location.hostname.indexOf('settings') >= 0) {
            attachZendesk()
          }

          initialised = true
        },
      }
    }
  ).name
