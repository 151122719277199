const angular = require('angular')
// const _ = require('lodash')
const { getUsageJWT } = require('../jwtHandler')

const GEN2_URL =
  'https://lookerstudio.google.com/embed/reporting/0c1d4378-b974-4416-a083-963b42f10f57/page/p_jipe81uxvc'
const LPS_ONLY_URL =
  'https://lookerstudio.google.com/embed/reporting/abc80ca6-af44-48b7-b07c-1d36e6df047a/page/p_jipe81uxvc'

module.exports = angular
  .module('mw.usage-report.directive', [
    require('../service/domain.service'),
    require('hapara-ng/src/experiments/experiments.service'),
  ])
  .directive('usageReport', ($sce, $stateParams, ExperimentsService) => {
    return {
      scope: true,
      link(scope) {
        const { domain, nid: siteCode } = $stateParams

        // Show loading icons until the data lands
        scope.loading = true
        if (ExperimentsService.getTdAdmin('HAP-10369-usage-report-gen2')) {
          getUsageJWT(domain, siteCode)
            .then(token => {
              scope.loading = false
              scope.url = $sce.trustAsResourceUrl(`${GEN2_URL}?params={"jwt":"${token}"}`)
              scope.$apply()
            })
            .catch(() => {
              // load report without JWT - will error and encourage reload
              scope.loading = false
              scope.url = $sce.trustAsResourceUrl(GEN2_URL)
              scope.$apply()
            })
        } else {
          scope.loading = false
          scope.url = $sce.trustAsResourceUrl(LPS_ONLY_URL)
        }
      },
      template: require('./usage-report.html'),
    }
  }).name
