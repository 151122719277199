const angular = require('angular')

module.exports = angular
  .module('sis-class-link.directive', [])
  .directive('sisClassLink', () => {
    return {
      scope: false,
      template: require('./class-link.html'),
    }
  })
  .name
