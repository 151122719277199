const angular = require('angular')
const { validate, validate_and_flatten } = require('../util')
const CONFIG = require('../config')

module.exports = angular
  .module('mw.url-load.directive', [
    require('hapara-ng/src/experiments/experiments.service'),
  ])
  .directive('urlLoad', () => {
    return {
      restrict: 'E',
      template: require('./url-load.html'),
      controller: (
        $scope,
        $state,
        $stateParams,
        $timeout,
        $rootScope,
        $http,
        PrefService,
        clipboard,
        Notification,
        DomainService
      ) => {
        $scope.resolveProvMan = true

        $scope.resolveProvMan = false
        $scope.provData = {}
        PrefService.get('prov_man').then(function(resp) {
          $scope.resolveProvMan = true
          $scope.provData = validate(resp.data)
        })
        $scope.checkBoxChange = true
        const { get_domain_nid } = DomainService
        $scope.removeStudentsSave = data => {
          $scope.removeStudentsSaveLoading = true
          const provManData = validate_and_flatten(data)
          $http
            .post(
              CONFIG.API_ROOT +
                `/${get_domain_nid()}?page=prov_man&format=json`,
              provManData
            )
            .then(() => {
              $scope.removeStudentsSaveLoading = false
              $scope.checkBoxChange = true
              Notification.success('Your changes are saved successfully')
            })
            .catch(() => {
              Notification.error('Failed to save synchronization, try again!')
            })
        }

        $scope.cancel = () => {
          $state.go('monkeywrench.base.loader.index')
        }
        $scope.domain = DomainService.get_domain_without_dash()
        $scope.nid = DomainService.get_nid()
        PrefService.get('csvload2').then(function(resp) {
          $scope.data = resp.data
        })
        $scope.resolving = false
        $scope.regenerate = () => {
          if ($scope.resolving) return
          $scope.resolving = true
          $scope.passkey_just_generated = false
          PrefService.regenerate_key()
            .then(resp => {
              $scope.data = resp.data
              $scope.passkey_just_generated = true
            })
            .finally(() => {
              $scope.resolving = false
            })
        }
        $scope.copyUrl = () => {
          clipboard.copyText($scope.data.csvUploadUrl.value)
          Notification.success('Copied!')
        }
        $scope.copyPassKey = () => {
          clipboard.copyText($scope.data.passkey.value)
          Notification.success('Copied!')
        }
      },
    }
  }).name
