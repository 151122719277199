const angular = require('angular')
const _ = require('lodash')

module.exports = angular
  .module('mw.school-group', [
    'gettext',
    require('angular-ui-router'),
    require('angular-ui-notification'),
    require('./config.service'),
  ])
  .directive(
    'mwSchoolGroup',
    ($stateParams, $timeout, gettextCatalog, MwConfigService) => {
      return {
        restrict: 'E',
        scope: true,
        link(scope) {
          angular.extend(scope, {
            schoolState: {
              error: undefined,
              isVerified: false,
              potentialGroup: '',
              potentialUsers: undefined,
              showForm: false,
              verifying: false,
            },
            /**
             * Clears the group state
             */
            clearGroup() {
              _.extend(scope.schoolState, {
                error: undefined,
                isVerified: false,
                potentialGroup: '',
                potentialUsers: undefined,
              })
              _.set(scope, 'school.PotentialManagersGroupURN', '')
              $timeout(scope.validateForm)
            },
            /**
             * Set the form valid based on whether the `IsDailyScanPotentialWorkGroupManagers` is true
             * or if there are valid sample users from the suggested group
             */
            validateForm() {
              if (!scope.schoolState.showForm || !scope.schoolState.form) {
                return
              }
              const hasUsers = _.get(scope, 'schoolState.potentialUsers.length')
              const valid = !!(hasUsers || scope.schoolState.isVerified)

              scope.schoolState.form.$setValidity('confirmedGroup', valid)
            },
            /**
             *
             */
            toggleForm() {
              scope.schoolState.showForm = !scope.schoolState.showForm
              $timeout(scope.validateForm)
            },
            /**
             * This is how we will know if we are in school mode or domain mode. No nid means domain view nid means school view.
             */
            nid: $stateParams.nid,
            /**
             * Validate the group. This will get the list of users.
             */
            verify() {
              const googleGroupId = scope.schoolState.potentialGroup
              const { domain } = $stateParams

              if (scope.isDuplicate(googleGroupId)) {
                scope.schoolState.error = gettextCatalog.getString(
                  `Google Groups have to be unique.`
                )
                return
              }
              scope.schoolState.verifying = true
              scope.schoolState.error = undefined
              MwConfigService.validate({ domain, googleGroupId })
                .then((res = {}) => {
                  scope.schoolState.potentialUsers = _.get(
                    res,
                    'ValidationReport.SampleUsers',
                    []
                  )
                  _.set(
                    scope,
                    'school.PotentialManagersGroupURN',
                    googleGroupId
                  )
                  scope.checkSitesValidity()
                  scope.validateForm()
                })
                .catch(e => {
                  scope.schoolState.error = _.get(e, 'data.Message')
                })
                .finally(() => {
                  scope.schoolState.verifying = false
                })
            },
            updateState() {
              const isVerified = _.get(
                scope,
                'school.IsDailyScanPotentialWorkGroupManagers'
              )
              angular.extend(scope.schoolState, {
                isVerified,
                potentialGroup: _.get(
                  scope,
                  'school.PotentialManagersGroupURN'
                ),
                potentialUsers: undefined,
              })
              $timeout(scope.validateForm)
            },
          })

          scope.$watch('config', scope.updateState)
        },
        template: require('./school-group.html'),
      }
    }
  ).name
