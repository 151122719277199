const angular = require('angular')
const _ = require('lodash')

module.exports = angular
  .module('hapara.shared.environment', [])
  // to check for what env we are in
  .constant(
    'env',
    function () {
      var host = window.location.hostname || '' // eslint-disable-line angular/window-service

      if (host.match(/^app-test\.hapara\.com$/)) {
        return 'test'
      } else if (host.match(/^app.hapara.com$/)) {
        return 'production'
      }

      // TODO: Remove when these are no longer required
      if (
        host.match(/^\w+-test\.(teacherdashboard|mystudentdashboard)\.com$/)
      ) {
        return 'test'
      } else if (host.match(/^test.teacherdashboard\.com$/)) {
        return 'test'
      } else if (host.match(/^\w+-test.+hapara-dashboard\.appspot\.com$/)) {
        return 'test'
      } else if (
        host.match(/^bau-dev\.(teacherdashboard|mystudentdashboard)\.com$/)
      ) {
        return 'bau-dev'
      } else if (host.match(/^bau-dev.+hapara-dashboard\.appspot\.com$/)) {
        return 'bau-dev'
      } else if (
        host.match(/^\w+-dev\.(teacherdashboard|mystudentdashboard)\.com$/)
      ) {
        return 'development'
      } else if (host.match(/^\w+-dev.+hapara-dashboard\.appspot\.com$/)) {
        return 'development'
      } else if (host.match(/^release-.*hapara-dashboard\.appspot\.com$/)) {
        return 'release'
      } else if (
        host.match(/^\w+-patch\.(teacherdashboard|mystudentdashboard)\.com$/)
      ) {
        return 'patch'
      } else if (host.match(/(teacherdashboard|mystudentdashboard)\.com$/)) {
        return 'production'
      } else if (host.match(/dockerhost/)) {
        return 'dockerhost'
      } else if (host.match(/dashboard|engage/)) {
        return 'local'
      } else {
        return 'development'
      }
    }.call(window)
  )
  /**
   * highlights/dashboard/hapara use this to define environment specific urls. Highlight's version
   * is simple and just uses groupSet('highlights').set('url', {'development: 'blah', 'all': 'blah'}). Dashboard
   * is more complex because there is a dev, uat, patch and production environment, each with different urls.
   */
  .provider('haparaEnv', function () {
    const prov = { env: {} }

    function groupCheck(group) {
      if (angular.isUndefined(prov.env[group])) {
        prov.env[group] = {
          set: function (key, val) {
            prov.set(group, key, val)
            return prov.env[group]
          },
        }
      }

      return prov.env[group]
    }

    angular.extend(prov, {
      groupSet: function (group) {
        return groupCheck(group)
      },
      set: function (groupName, key, val) {
        const group = groupCheck(groupName)

        const groupVal = _.get(group, key, {})
        // if we are passed a single value, use it as default "all"
        angular.extend(groupVal, angular.isObject(val) ? val : { all: val })
        group[key] = groupVal

        return prov
      },
      $get: function ($log, env) {
        const environment = _.mapValues(prov.env, group => {
          return _.mapValues(group, val => {
            return val[env] ? val[env] : val['all']
          })
        })

        $log.debug('Environment is ', environment)
        return environment
      },
    })

    return prov
  }).name
