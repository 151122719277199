const angular = require('angular')
const _ = require('lodash')
module.exports = angular
  .module('mw.self-prov-id.directive', [])
  .directive('selfProvId', () => {
    return {
      restrict: 'E',
      scope: true,
      controller: ($scope, $state, $uibModal, PrefService, selfProvStore) => {
        angular.extend($scope, selfProvStore)
        $scope.isLoadCSVDisabled = () => {
          const ou_num = _.reduce(
            $scope.domains,
            (acc, domain) =>
              acc + domain.student_ous.length + domain.teacher_ous.length,
            0
          )
          return ou_num > 0
        }
        $scope.isLoadOUDisabled = () => {
          return (
            _.some($scope.csv.teacher, csv => csv.file) ||
            _.some($scope.csv.student, csv => csv.file)
          )
        }

        $scope.goLoadOU = e => {
          if ($scope.isLoadOUDisabled()) {
            e.preventDefault()
            $uibModal.open({
              scope: $scope,
              template: require('./modal-ou-not-allowed.html'),
            })
          }
        }
        $scope.goLoadCSV = e => {
          if ($scope.isLoadCSVDisabled()) {
            e.preventDefault()
            $uibModal.open({
              scope: $scope,
              template: require('./modal-csv-not-allowed.html'),
            })
          }
        }
        $scope.prev = () => {
          $state.go('monkeywrench.self-prov.detail')
        }
      },
      template: require('./id.html'),
    }
  }).name
