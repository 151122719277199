const angular = require('angular')

module.exports = angular
  .module('hap.feedback-service', [require('../pugs/pug-phone.service')])
  .factory('FeedbackService', function($http, PugPhone) {
    const url = PugPhone.dial('/v2/services/feedback')

    return {
      send(data) {
        return $http.post(url, data)
      },
    }
  }).name
