const angular = require('angular')

module.exports = angular
  .module('sis-power-school.directive', [])
  .directive('sisPowerSchool', () => {
    return {
      scope: false,
      template: require('./power-school.html'),
    }
  })
  .name
