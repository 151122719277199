export const map = {
  prov_man_folder_option: {
    notUsed: 'Do not create folders*',
    className: 'Each student has one folder matching ClassName. (No spreadsheet column required).',
    columnValue: 'Each class has folder(s) named in the Class spreadsheet column titled \'Subject Folders\'',
    folderNamesAsSpecified: 'Each student has the same set of subject folder(s) listed below. (No spreadsheet column required).',
  },
  prov_man_folder_option_enumerated: "folderNamesAsSpecified",
  prov_man_folder_option_unused: "notUsed",
  prov_man_blog_option: {
    notUsed: 'Do not show Blogger content',
    teacherAll: 'Show content from all blogs where student is a co-author. (No spreadsheet column required).',
    columnValue: 'Show content of the blog(s) specified in the Classes spreadsheet column titled \'Student Blogs\'',
  },
  prov_man_site_option: {
    notUsed: 'Do not show Site content',
    teacherAll: 'Show content from all Sites where student is a co-author. (No spreadsheet column required).',
    columnValue: 'Show content from the student site(s) named in the Classes spreadsheet column titled \'Student Sites\'. Create missing sites.',
  },
  prov_man_calendar_option: {
    notUsed: 'Class Calendars are not created',
    className_yearSuffix: 'Create a class calendar named ClassName_YearSuffix. (No spreadsheet column required).',
    columnValue: 'Create the class calendar named in the Class spreadsheet column titled \'Class Calendar\'',
  },
  prov_man_gmail_option: {
    adminsTeachers: 'Admins and teachers who can view the class',
    teachers: 'The teacher(s) of the class. (No spreadsheet column required).',
    columnValue: 'The teacher(s) in the Google Group(s) named in the Class spreadsheet column titled \'Gmail Viewers\'',
  },
  prov_man_doc_type: {
    spreadsheet: 'Google Sheet',
    csv: 'CSV file',
  },
  prov_sis_folder_option: {
    notUsed: 'Do not create folders*',
    classEmail: 'Each student has one folder matching ClassEmail',
    className: 'Each student has one folder matching ClassName',
    courseName: 'Each student has one folder matching CourseName',
    courseNumber: 'Each student has one folder matching CourseNumber', // defunct - remove after HAP-6038
    folderNamesAsSpecified: 'Each student has the same set of subject folder(s) listed below',
  },
  prov_sis_folder_option_enumerated: "folderNamesAsSpecified",
  prov_sis_folder_option_unused: "notUsed",
  prov_sis_blog_option: {
    notUsed: 'Do not show Blogger content',
    teacherAll: 'Show content from all blogs where student is a co-author',
  },
  prov_sis_site_option: {
    notUsed: 'Do not show Site content',
    teacherAll: 'Show content from all Sites where student is a co-author',
  },
  prov_sis_calendar_option: {
    notUsed: 'Class Calendars are not created',
    className_yearSuffix: 'Create a class calendar named ClassName_YearSuffix',
  },
  prov_sis_gmail_option: {
    adminsTeachers: 'Admins and teachers who can view the class',
    teachers: 'The teacher(s) of the class',
  },
  docs_subject_folder_naming_convention: {
    'noExtension': 'No suffix',
    'firstNamelastName': '- FirstName LastName',
    'lastNamefirstName': '- LastName, FirstName',
    'userId': '- StudentEmail',
  },
  sis_int_mode: {
    'CLEVER': 'Clever',
    'POWER_SCHOOL': 'PowerSchool',
    'CLASS_LINK': 'ClassLink'
  },
  prov_sis_class_name_option: {
    'classEmail': 'Same as ClassEmail',
    'courseName': 'CourseName',
    'courseName-period': 'CourseName-Period',
    'courseName-section': 'CourseName-Section',
    'courseName-sisId': 'CourseName-SISID',
    'courseName-period-sisId': 'CourseName-Period-SISID',
    'courseName-section-sisId': 'CourseName-Section-SISID',
  },
}
