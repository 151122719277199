const angular = require('angular')
const _ = require('lodash')
module.exports = angular
  .module('mw.manual-load.directive', [])

  .directive('manualLoad', () => {
    return {
      restrict: 'E',
      template: require('./manual-load.html'),
      link: ($scope, element) => {
        $scope.csv_file_input = element[0].querySelector('#csv_file')

        angular.element($scope.csv_file_input).bind('change', function() {
          $scope.$apply(() => {
            $scope.csv_file_name = _.get(this, 'files.0.name', '')
          })
        })
      },
      controller: (
        $scope,
        $state,
        $rootScope,
        $timeout,
        $uibModal,
        PrefService
      ) => {
        PrefService.get('prov_man').then(function(resp) {
          $scope.data = resp.data

          // If prov_td_features_enabled = FALSE then do these checks
          if (!$scope.data.prov_td_features_enabled.value) {
            var form = {}
            $scope.data.prov_man_folder_option.value =
              $scope.data.prov_man_folder_option.options[0]
            form.prov_man_folder_option =
              $scope.data.prov_man_folder_option.options[0]

            if (
              $scope.data.prov_man_blog_option.value !==
                $scope.data.prov_man_blog_option.options[0] &&
              $scope.data.prov_man_blog_option.value !==
                $scope.data.prov_man_blog_option.options[1]
            ) {
              $scope.data.prov_man_blog_option.value =
                $scope.data.prov_man_blog_option.options[1]
              form.prov_man_blog_option =
                $scope.data.prov_man_blog_option.options[1]
            }

            if (
              $scope.data.prov_man_site_option.value !==
                $scope.data.prov_man_site_option.options[0] &&
              $scope.data.prov_man_site_option.value !==
                $scope.data.prov_man_site_option.options[1]
            ) {
              $scope.data.prov_man_site_option.value =
                $scope.data.prov_man_site_option.options[1]
              form.prov_man_site_option =
                $scope.data.prov_man_site_option.options[1]
            }

            PrefService.save('prov_man', form)
          }
          $scope.doc_id = {
            value: '',
          }
          $scope.data_upload = {
            value: 'by_name',
          }
          $scope.doc_name = {
            value: '',
          }
          $scope.gs_loading_setting = {
            loadAll: '',
            allowConflict: '',
          }
          $scope.csv_loading_setting = {
            loadAll: '',
            fullSync: '',
            multipleYears: '',
          }
          $scope.wizard = {
            steps: ['Configuration settings', 'Load SpreadSheet', 'Done'],
          }

          $scope.wizard.step_cur_index = 0
          $scope.wizard.step_total = $scope.wizard.steps.length
          $scope.wizard.step_prev = () => {
            $scope.wizard.step_cur_index =
              (--$scope.wizard.step_cur_index + $scope.wizard.step_total) %
              $scope.wizard.step_total
          }
          $scope.wizard.step_next = () => {
            $scope.wizard.step_cur_index =
              ++$scope.wizard.step_cur_index % $scope.wizard.step_total
          }
          $scope.wizard.step_cancel = () => {
            $state.go('monkeywrench.base.loader.index')
          }
          $scope.wizard.step_load = () => {
            var modalInstance = $uibModal.open({
              animation: true,
              template: require('../modal/load.html'),
              size: 'md',
              scope: $scope,
              controller: [
                '$scope',
                scope => {
                  scope.ok = function() {
                    var promise = null
                    if (
                      $scope.data.prov_man_doc_type.value ===
                      $scope.data.prov_man_doc_type.options[0]
                    ) {
                      var docid =
                        $scope.data_upload.value === 'by_name'
                          ? $scope.doc_id_verified_from_doc_name
                          : $scope.doc_id.value
                      promise = PrefService.load(
                        docid,
                        $scope.gs_loading_setting
                      )
                    } else {
                      promise = PrefService.load_csv(
                        $scope.csv_file_input.files[0],
                        $scope.csv_loading_setting
                      )
                    }

                    promise.then(resp => {
                      var data = resp.data
                      if (data.loadid.value) {
                        $scope.loadid = data.loadid.value
                        $scope.wizard.step_next()
                      }
                    })
                    modalInstance.close()
                  }
                  scope.cancel = function() {
                    modalInstance.dismiss('cancel')
                  }
                },
              ],
            })
          }

          $scope.wizard.step_finish = () => {}
          $scope.can_load = () => {
            return (
              ($scope.data_upload.value === 'by_name' && $scope.doc_verified) ||
              $scope.data_upload.value === 'by_dockey'
            )
          }
          $scope.onchange = () => {
            // everytime the spreadsheet is changed, it needs to verify
            _.set($scope, 'doc_verified', null)
          }
          $scope.verify_doc = () => {
            var doc_name = $scope.doc_name.value
            PrefService.verify_doc(doc_name).then(resp => {
              var docid = _.get(resp, 'data.docid.value', '')
              $scope.doc_verified = !!docid
              $scope.doc_id_verified_from_doc_name = docid
            })
          }
          $scope.find_doc = doc_name => {
            return PrefService.find_doc(doc_name).then(function(response) {
              return _.isArray(response.data) ? response.data : []
            })
          }
        })
      },
    }
  }).name
