const angular = require('angular')
const { validate, validate_and_flatten } = require('../util')

module.exports = angular
  .module('mw.sis-integration.directive', [])
  .directive('sisIntegration', () => {
    return {
      restrict: 'E',
      template: require('./sis-integration.html'),
      controller: (
        $scope,
        $state,
        $stateParams,
        $rootScope,
        PrefService,
        Notification,
        $uibModal,
      ) => {
        $scope.cancel = () => {
          $state.go('monkeywrench.base.loader.index')
        }

        PrefService.get('prov_sis').then(function (resp) {
          $scope.data = validate(resp.data)

          $scope.resolving = false
          $scope.save = () => {
            if ($scope.resolving) return
            $scope.resolving = true
            var form = validate_and_flatten($scope.data)
            PrefService.save('prov_sis', form)
              .then(() => {
                Notification.success('Saved!')
              })
              .catch(() => {
                Notification.error('Failed to save, try to save again!')
              })
              .finally(() => {
                $scope.resolving = false
              })
          }

          /**
           * sync class
           * @type {Boolean}
           */
          $scope.syncing_class = false
          $scope.sync_class = () => {
            if ($scope.syncing_class) return
            var modalInstance = $uibModal.open({
              animation: true,
              template: require('../modal/sync.html'),
              size: 'md',
              controller: [
                '$scope',
                scope => {
                  scope.ok = function () {
                    $scope.syncing_class = true
                    PrefService.sync_class()
                      .then(() => {
                        Notification.success('Synchronization started')
                      })
                      .catch(() => {
                        Notification.error('Failed to start synchronization, try again!')
                      })
                      .finally(() => {
                        $scope.syncing_class = false
                      })
                    modalInstance.close()
                  }
                  scope.cancel = function () {
                    modalInstance.dismiss('cancel')
                  }
                },
              ],
            })
          }

          /**
           * sync student
           * @type {Boolean}
           */
          $scope.syncing_student = false
          $scope.sync_student = () => {
            if ($scope.syncing_student) return
            var modalInstance = $uibModal.open({
              animation: true,
              template: require('../modal/sync.html'),
              size: 'md',
              scope: $scope,
              controller: [
                '$scope',
                scope => {
                  scope.ok = function () {
                    $scope.syncing_student = true
                    PrefService.sync_student()
                      .then(() => {
                        Notification.success('Synchronization started')
                      })
                      .catch(() => {
                        Notification.error('Failed to start synchronization, try again!')
                      })
                      .finally(() => {
                        $scope.syncing_student = false
                      })
                    modalInstance.close()
                  }
                  scope.cancel = function () {
                    modalInstance.dismiss('cancel')
                  }
                },
              ],
            })
          }
        })
      },
    }
  }).name
