const angular = require('angular')
const _ = require('lodash')

module.exports = angular
  .module('mw.self-prov.service', [])
  .factory('selfProvStore', () => {
    return {
      addDomain (domain) {
        this.domains.push({
          name: domain,
          student_ous: [],
          teacher_ous: [],
        })
      },
      deleteDomain (domain) {
        _.pull(this.domains, domain)
      },
      details: {
        name: '',
        email: '',
        contact: '',
        institution: '',
        country: '',
        timezone: '',
      },
      domains: [
        {
          name: 'domain 1',
          student_ous: [],
          teacher_ous: [],
        },
        {
          name: 'domain 2',
          student_ous: [],
          teacher_ous: [],
        },
      ],
      csv: {
        teacher: [
          {
          },
        ],
        student: [
          {
          },
        ],
      },
    }
  })
  .name
