const angular = require('angular')

module.exports = angular
  .module('mw.email-config', [])
  .directive('mwEmailConfig', () => {
    return {
      restrict: 'E',
      scope: false,
      link (scope) {
        scope.$watch('config.updating', (newValue, oldValue) => {
          if (!newValue && oldValue && !scope.config.errored) {
            scope.email_form.$setPristine()
          }
        })
      },
      template: require('./email-config.html'),
    }
  })
  .name
