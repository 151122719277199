const angular = require('angular')
const _ = require('lodash')

module.exports = angular
  .module('mw.analytics-load-ou.directive', [])
  .directive('analyticsLoadOu', () => {
    return {
      restrict: 'E',
      scope: true,
      controller: ($scope, $state, $rootScope, PrefService, Notification) => {
        $scope.save_enabled = () => false

        PrefService.get('an')
          .then(res => {
            $scope.domains = res.data.domains

            $scope.save_enabled = () => {
              var atLeast1OUPerDomain = _.every(
                $scope.domains,
                domain =>
                  domain.teacher_ous.length + domain.student_ous.length > 0
              )
              var allOUValid = _.every($scope.domains, domain => {
                var teacherOUValid = _.every(
                  domain.teacher_ous,
                  ou => ou.invalid === false
                )
                var studentOUValid = _.every(
                  domain.student_ous,
                  ou => ou.invalid === false
                )
                return teacherOUValid && studentOUValid
              })
              var atLeast1StudentOU = _.some(
                $scope.domains,
                domain => domain.student_ous.length > 0
              )
              var atLeast1TeacherOU = _.some(
                $scope.domains,
                domain => domain.teacher_ous.length > 0
              )

              $scope.atLeast1OUPerDomain = atLeast1OUPerDomain
              $scope.atLeast1StudentOU = atLeast1StudentOU
              $scope.atLeast1TeacherOU = atLeast1TeacherOU

              return (
                atLeast1OUPerDomain &&
                allOUValid &&
                atLeast1StudentOU &&
                atLeast1TeacherOU
              )
            }
            // OUs
            $scope.save = () => {
              var domains = _.mapValues($scope.domains, domain => {
                return {
                  teacher_ous: _.map(domain.teacher_ous, ou => ou.text),
                  student_ous: _.map(domain.student_ous, ou => ou.text),
                }
              })
              PrefService.setOU({
                domains: domains,
              }).then(() => {
                Notification.success('Saved!')
              })
            }
          })
          .catch(({ data = {} }) => {
            if (data.error) {
              Notification.error(data.error)
            }
          })
      },
      template: require('./analytics-load-ou.html'),
    }
  }).name
