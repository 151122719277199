const angular = require('angular')
const _ = require('lodash')

module.exports = angular
  .module('mw.self-prov-detail.directive', [])
  .directive('selfProvDetail', () => {
    return {
      restrict: 'E',
      scope: true,
      controller: (
        $scope,
        $state,
        $uibModal,
        PrefService,
        Notification,
        CountriesService,
        selfProvStore,
        userFactoryGoogle
      ) => {
        angular.extend($scope, selfProvStore, CountriesService, {
          next() {
            $scope.form.$setSubmitted()
            if (!$scope.form.$invalid) {
              $state.go('monkeywrench.self-prov.id.index')
            }
          },
          addDomain: domain => {
            selfProvStore.addDomain(domain)
          },
          deleteDomain: domain => {
            selfProvStore.deleteDomain(domain)
          },
          openAddDomainDialog: () => {
            $uibModal.open({
              scope: $scope,
              template: require('./modal-add-domain.html'),
            })
          },
        })

        // get name & email from login info
        $scope.$watch(
          () => userFactoryGoogle.user,
          ({ name = '', email = '' } = {}) => {
            $scope.details.name = name
            $scope.details.email = email
          }
        )

        $scope.$watch('details.country', country => {
          _.set($scope, 'timezones', CountriesService.getTimezones(country))

          const tz = _.get($scope, 'details.timezone')
          // if current timezone is not for current country
          if (!_.some($scope.timezones, timezone => timezone.id === tz)) {
            _.set($scope, 'details.timezone', _.get($scope, 'timezones.0.id'))
          }
        })
      },
      template: require('./detail.html'),
    }
  }).name
