const angular = require('angular')
const _ = require('lodash')

module.exports = angular
  .module('mw.classes-config', [
    'gettext',
    require('angular-ui-router'),
    require('./validators/is-google-classable.directive'),
    require('./config.service'),
  ])
  .directive('mwClassesConfig', ($timeout, $stateParams, MwConfigService) => {
    return {
      restrict: 'E',
      scope: false,
      link(scope) {
        function onUpdate() {
          scope.classesState.showDomainFormFields = _.get(
            scope,
            'config.UserValidationInfo.IsDailyScanPotentialWorkGroupManagers'
          )
          const sites = _.get(scope, 'config.UserValidationInfo.SitesSetupInfo')
          scope.classesState.showSchoolFormFields = _.some(sites, site => {
            return (
              site.IsDailyScanPotentialWorkGroupManagers ||
              site.Code.toLowerCase() === $stateParams.nid.toLowerCase()
            )
          })
        }

        angular.extend(scope, {
          classesState: {
            showDomainFormFields: false,
            showSchoolFormFields: false,
          },
          /**
           * This is how we will know if we are in school mode or domain mode. No nid means domain view nid means school view.
           */
          nid: $stateParams.nid,
          /**
           * Checks domain & sites groups to see if it already exists
           * Says it's a duplicate if `groupId` is falsey
           * @param {String} groupId
           */
          isDuplicate(groupId) {
            const sites = _.get(scope, 'config.UserValidationInfo.SitesSetupInfo')
            const groupIds = _.map(sites, 'PotentialManagersGroupURN')
            const domainGroup = _.get(scope, 'config.UserValidationInfo.PotentialManagersGroupURN')

            groupIds.push(domainGroup)
            return !groupId || _.includes(groupIds, groupId)
          },
          /**
           * Clears the manager group if showDomainFormFields is off
           */
          toggleDomainGroup() {
            if (!scope.classesState.showDomainFormFields) {
              _.set(scope, 'config.UserValidationInfo.PotentialManagersGroupURN', '')
              _.set(scope, 'config.UserValidationInfo.IsDailyScanPotentialWorkGroupManagers', false)
            }
            // something
          },
          doSaveDomainLevel() {
            const googleGroupId = _.get(
              scope,
              'config.UserValidationInfo.PotentialManagersGroupURN'
            )
            // Look over school groups and handle them
            const sites = _.get(scope, 'config.UserValidationInfo.SitesSetupInfo', [])
            // We need to check if scope.ui.showSchoolFormFields is set if unticked set all groups for downgrading.
            const domainLevelPayload = {
              GroupId: googleGroupId,
              SiteUpdates: _.map(sites, function (site) {
                // if school groups is disabled downgrade everything.
                if (scope.classesState.showSchoolFormFields) {
                  return { HapSiteId: site.Code, GroupId: site.PotentialManagersGroupURN }
                } else {
                  return { HapSiteId: site.Code, GroupId: '' }
                }
              }),
            }
            const { domain } = $stateParams
            scope.config.updating = true
            MwConfigService.confirmDomainGroups({ domain, domainLevelPayload })
              // relies on `applyConfig` being in the scope
              .then(response => {
                scope.applyConfig(response)
                scope.config.loaded = true
              })
              .catch(e => {
                scope.config.errored = e
              })
              .finally(() => {
                scope.config.updating = false
              })
          },
          doSaveSchoolsLevel() {
            const [site] = _.get(scope, 'config.UserValidationInfo.SitesSetupInfo', [])
            if (site) {
              const { domain } = $stateParams
              const nid = site.Code
              const googleGroupId = site.PotentialManagersGroupURN
              MwConfigService.confirm({ domain, googleGroupId, nid })
                .catch(e => {
                  scope.config.errored = e
                })
                .finally(() => {
                  scope.config.updating = false
                })
            }
          },
        })

        onUpdate()

        scope.$watch('config.updating', (newValue, oldValue) => {
          if (!newValue && oldValue && !scope.config.errored) {
            scope.classes_form.$setPristine()
          }
        })
      },
      template: require('./classes-config.html'),
    }
  }).name
