const angular = require('angular')
const _ = require('lodash')
module.exports = angular
  .module('mw.self-prov-id-csv.directive', [])
  .directive('selfProvIdCsv', () => {
    return {
      restrict: 'E',
      scope: true,
      controller: ($scope, $state, selfProvStore) => {
        angular.extend($scope, selfProvStore)
        $scope.addFile = csvs => {
          csvs.push({
            file: null,
          })
        }

        $scope.uploadFile = (fileEntry, files) => {
          fileEntry.file = files[0]
        }
        $scope.deleteFile = (fileEntries, file) => {
          _.pull(fileEntries, file)
        }
        $scope.prev = () => {
          $state.go('monkeywrench.self-prov.id.index')
        }
      },
      template: require('./id-csv.html'),
    }
  })
  .directive('fileOnChange', function() {
    return {
      restrict: 'A',
      scope: {
        fileOnChange: '&',
        fileEntry: '=',
      },
      link: function(scope, element) {
        element.bind('change', function() {
          scope.$apply(() => {
            scope.fileOnChange()(scope.fileEntry, this.files)
          })
        })
      },
    }
  }).name
