const angular = require('angular')

module.exports = angular
  .module('hap.confirm-dialog', [])
  .factory('confirmDialog', ($window) => {
    return {
      /**
       * Add a testable reference to the window's confirm dialog
       */
      confirm (message) {
        return $window.confirm(message)
      },
    }
  })
  .name
