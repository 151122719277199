const angular = require('angular')
const _ = require('lodash')
module.exports = angular
  .module('mw.ou-editor.directive', [])
  .directive('ouEditor', () => {
    return {
      restrict: 'E',
      scope: true,
      controller: ($scope, $q, $state, PrefService) => {
        $scope.hasOU = domain => {
          return domain.student_ous.length + domain.teacher_ous.length > 0
        }
        $scope.onAdding = (existingOUs, OUtoAdd) => {
          // add '/'
          if (OUtoAdd.text.indexOf('/') !== 0) {
            OUtoAdd.text = '/' + OUtoAdd.text
          }
          // not add duplicated OU
          if (_.some(existingOUs, ou => ou.text === OUtoAdd.text)) {
            return false
          }
          return OUtoAdd
        }
        $scope.verifyOUS = (ous, type, domain) => {
          ous.verifying = true
          var promises = _.map(ous, ou => {
            return PrefService.verifyOU(type, ou.text, domain)
              .then(() => {
                ou.invalid = false
              })
              .catch(() => {
                ou.invalid = true
              })
          })
          $q.all(promises).finally(() => {
            ous.verifying = false
            ous.invalidOUCount = _.filter(ous, ou => ou.invalid).length
          })
        }
      },
      template: require('./ou-editor.html'),
    }
  }).name
