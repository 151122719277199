const angular = require('angular')
const CONFIG = require('../config')
module.exports = angular
  .module('mw.reauthorization.service', [])
  .service('ReauthorizationService', $http => {
    return {
      isAuthorized: (domain, nid) => {
        if (nid) {
          return $http.get(
            CONFIG.API_ROOT + `/${domain}/${nid}/app/isauthorized`
          )
        }
        return $http.get(CONFIG.API_ROOT + `/${domain}/app/isauthorized`)
      },
      submitReauthTicket: (domain, reason) => {
        if (!reason) {
          return
        } else {
          return $http.post(CONFIG.PUGS_BASE + `/services/support/appauth`, {
            userMsg: reason,
            domain: domain,
          })
        }
      },
    }
  }).name
