const angular = require('angular')
const config = require('../config')

module.exports = angular
  .module('mw.config.service', [
    'gettext',
    require('angular-ui-notification'),
    require('hapara-ng/src/pugs/pug-phone.service'),
  ])
  .factory('MwConfigService', (
    $q,
    $http,
    $timeout,
    Notification,
    gettextCatalog,
    PugPhone,
  ) => {
    const $MCS = {
      /**
       * Tests whether Google Classroom access works
       * @param {String} domain
       */
      checkGoogleClassable (domain) {
        const url = PugPhone.dial(`/v2/pugs/customer-setup/validation/urn/${domain.replace(/^-/, '')}/validate-gclassroom`)
        return $http.get(url)
          .then((response = {}) => response.data)
          .catch((e = {}) => {
            const err = JSON.parse(e.data || '{}')
            if (err.ActionCode !== 'API_DISABLED' && err.ActionCode !== 'MISSING_SCOPE') {
              Notification.error(gettextCatalog.getString('Something went wrong confirming data access rights. Please try again in 30 seconds.'))
            }
            return e
          })
      },
      /**
       * Confirm all google groups on the domain level page
       * @param {Object} opts
       */
      confirmDomainGroups (opts = {}) {
        const { domain, domainLevelPayload, } = opts
        const url = `${config.API_ROOT}/${domain}/optional/potential-teachers/set-all`
        return $http.post(url, domainLevelPayload)
          .then((response = {}) => response.data)
          .catch((err = {}) => {
            err = JSON.parse(err.data || '{}')
            if (err.Message) {
              Notification.error(err.Message)
            } else {
              Notification.error(gettextCatalog.getString('Something went wrong confirming groups. Please try again in 30 seconds.'))
            }
          })
      },
      /**
       * Validate a google group
      },
      /**
       * Confirm google group
       * @param {Object} opts
       */
      confirm (opts = {}) {
        const { domain, googleGroupId, nid, } = opts
        let url
        if (nid) {
          url = `${config.API_ROOT}/${domain}/${nid}/optional/potential-teachers/set?googleGroupId=${googleGroupId}`
        } else {
          url = `${config.API_ROOT}/${domain}/optional/potential-teachers/set?googleGroupId=${googleGroupId}`
        }

        return $http.post(url)
          .then((response = {}) => response.data)
          .catch((err = {}) => {
            err = JSON.parse(err.data || '{}')

            if (err.Message) {
              Notification.error(err.Message)
            } else {
              Notification.error(gettextCatalog.getString('Something went wrong confirming groups. Please try again in 30 seconds.'))
            }
          })
      },
      /**
       * Validate a google group
      },
      /**
       * Fetch the config
       * @param {Object} opts
       */
      fetch (opts = {}) {
        const { domain, nid, } = opts
        let url
        if (nid) {
          url = `${config.API_ROOT}/${domain}/${nid}/optional`
        } else {
          url = `${config.API_ROOT}/${domain}/optional`
        }

        return $http.get(url)
          .then((response = {}) => response.data)
      },
      /**
       * Update the settings
       * @param {Object} opts
       */
      set (opts = {}) {
        const { domain, settings, nid, } = opts
        let url
        if (nid) {
          url = `${config.API_ROOT}/${domain}/${nid}?page=optionalsettings&format=json`
        } else {
          url = `${config.API_ROOT}/${domain}?page=optionalsettings&format=json`
        }

        return $http.post(url, settings)
          .then((response = {}) => response.data)
          .catch((err = {}) => {
            err = JSON.parse(err.data || '{}')

            if (err.Message) {
              Notification.error(err.Message)
            } else {
              Notification.error(gettextCatalog.getString('Something went wrong trying to set configuration. Please try again in 30 seconds.'))
            }
          })
      },
      /**
       * Validate a google group
       * @param {Object} opts
       */
      validate (opts = {}) {
        const { domain, googleGroupId, nid, } = opts
        let url
        if (nid) {
          url = `${config.API_ROOT}/${domain}/${nid}/optional/potential-teachers/validate?googleGroupId=${googleGroupId}`
        } else {
          url = `${config.API_ROOT}/${domain}/optional/potential-teachers/validate?googleGroupId=${googleGroupId}`
        }

        return $http.post(url)
          .then((response = {}) => response.data)
      },
      /**
       * Generator to validate school group
       * @param {FSA} action
       */
      focusInput: (element) => {
        $timeout(function () {
          angular.element(element.find('input'))[0].focus()
        })
      },
    }

    return $MCS
  })
  .name
