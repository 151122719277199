const angular = require('angular')

const _ = require('lodash')
const path = require('path')
const files = require.context('../../../hapara-assets/src/icons', true, /\.svg$/)
const spinnables = ['spinner', 'loader']

const icons = _.reduce(
  files.keys(),
  (memo, f) => {
    // Webpack 1 and Webpack 2 sturcture things slightly differently this if statement handles this. Can be removed when all our projects are off webpack 1.
    const webpackAsset = files(f)
    if (webpackAsset.default) {
      memo[path.basename(f, '.svg')] = webpackAsset.default
    } else {
      memo[path.basename(f, '.svg')] = webpackAsset
    }
    return memo
  },
  {}
)

module.exports = angular.module('hap.icon', []).directive('hapIcon', () => {
  return {
    restrict: 'E',
    scope: true,
    link(scope, el, attr) {
      attr.$observe('svg', key => {
        const { id = '_fallbackIcon', viewBox = '0 0 28 28' } = icons[key] || {}
        const tokens = (viewBox || '').split(' ')
        const [x, y, w, h] = tokens
        let width
        let height
        try {
          height = window.getComputedStyle(el[0]).getPropertyValue('font-size').replace(/px$/, '')
        } catch (e) {
          height = h
        }
        width = Number(w * (height / h)).toFixed(1)

        el.html(`
            <svg alt="${
              attr.alt || ''
            }" xmlns="http://www.w3.org/2000/svg" viewBox="${viewBox}" height="${
          attr.height || height
        }" x="${x}" y="${y}" width="${attr.width || width}">
              <g fill="currentColor">
                <use xlink:href="#${id}" />
              </g>
            </svg>
          `)

        if (_.includes(spinnables, id) || _.has(attr, 'spin')) {
          el.addClass('hap-icon-spin')
        } else {
          el.removeClass('hap-icon-spin')
        }
      })
    },
  }
}).name
