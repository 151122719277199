const angular = require('angular')
const _ = require('lodash')
module.exports = angular
  .module('mw.analytics-load-student-csv.directive', [])
  .directive('analyticsLoadStudentCsv', () => {
    return {
      restrict: 'E',
      scope: true,
      link: ($scope, element) => {
        $scope.csv_file_input = element[0].querySelector('#csv_file')

        angular.element($scope.csv_file_input).bind('change', function() {
          $scope.$apply(() => {
            $scope.csv_file_name = _.get(this, 'files.0.name', '')
          })
        })
      },
      controller: ($scope, $state, PrefService, Notification) => {
        $scope.uploading = false
        $scope.upload = () => {
          if ($scope.uploading) return

          $scope.uploading = true

          PrefService.load_csv_for_an($scope.csv_file_input.files[0], 'student')
            .then(res => {
              var errMsg = _.get(res, 'data.erroraction.value')

              var ignoredCols = _.get(
                res,
                'data.warningMessage.value.ignored',
                []
              )
              var ignoredMsg = _.map(
                ignoredCols,
                col =>
                  `WARNING: The column "${col}" has not been imported as Hapara Advanced Analytics is not enabled.<br>`
              ).join('')
              var invalidCols = _.get(
                res,
                'data.warningMessage.value.invalid',
                []
              )
              var invalidMsg = _.map(
                invalidCols,
                col =>
                  `WARNING: The column '${col}' has not been imported as it was not understood.<br>`
              ).join('')
              if (errMsg) {
                Notification.error(errMsg)
              } else {
                if (ignoredMsg) {
                  Notification.error(ignoredMsg)
                }
                if (invalidMsg) {
                  Notification.error(invalidMsg)
                }
                if (!ignoredMsg && !ignoredMsg) {
                  Notification.success(
                    'CSV Load started. The results will be emailed to you'
                  )
                }
              }
            })
            .catch(() => {
              Notification.error('Failed')
            })
            .finally(() => {
              $scope.uploading = false
            })
        }
      },
      template: require('./analytics-load-student-csv.html'),
    }
  }).name
