const angular = require('angular')

module.exports = angular
  .module('mw.domain-audit', [
    require('angular-ui-router'),
    require('../base-states'),
    require('./domain-audit.directive'),
  ])
  .config($stateProvider => {
    $stateProvider
      .state('monkeywrench.base.domain-audit', {
        abstract: true,
        url: '/domain-audit',
        views: {
          body: {
            template: '<div ui-view><loader-index/></div>',
          },
        },
        data: {
          greyBackGround: true,
        },
      })
      .state('monkeywrench.base.domain-audit.index', {
        url: '/index',
        template: '<domain-audit></domain-audit>',
      })
  }).name
