const angular = require('angular')
module.exports = angular
  .module('mw.domain.service', [])
  .provider('DomainService', () => {
    var _domain = ''
    var _nid = ''

    const without_dash = (str) => {
      if (str[0] === '-') {
        return str.substr(1)
      }
      return str
    }
    return {
      set_domain_nid: (domain, nid) => {
        _domain = domain
        _nid = nid.toLowerCase()
      },

      $get: () => {
        return {
          get_domain: () => _domain,
          get_nid: () => _nid,
          get_domain_nid: () => {
            return _nid ? `${_domain}/${_nid}` : _domain
          },
          // without dash
          get_domain_without_dash: () => {
            return without_dash(_domain)
          },
        }
      },
    }
  })
  .name
