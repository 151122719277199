const angular = require('angular')
const { validate_and_flatten } = require('../util')
module.exports = angular
  .module('mw.account-creation.directive', [])
  .directive('accountCreation', () => {
    return {
      restrict: 'E',
      template: require('./account-creation.html'),
      controller: (
        $scope,
        $rootScope,
        $state,
        $stateParams,
        PrefService,
        Notification
      ) => {
        if (!$rootScope.mw.prov_td_features_enabled.value) {
          return $state.go('monkeywrench.base.loader.index')
        }

        PrefService.get('accounts').then(function(resp) {
          $scope.data = resp.data
          if ($scope.data.email_account_alg.value === '') {
            $scope.data.email_account_alg.value = 'none'
          }
          $scope.resolving = false
          $scope.save = () => {
            if ($scope.resolving) return
            $scope.resolving = true
            var form = validate_and_flatten($scope.data)
            if (form.email_account_alg.value === 'none') {
              form.email_account_alg.value = ''
            }
            PrefService.save('accounts', form)
              .then(() => {
                Notification.success('Saved!')
              })
              .catch(() => {
                Notification.error('Failed to save, try to save again!')
              })
              .finally(() => {
                $scope.resolving = false
              })
          }
        })
        $scope.cancel = () => {
          $state.go('monkeywrench.base.loader.index')
        }
      },
    }
  }).name
