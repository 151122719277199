const angular = require('angular')

module.exports = angular
  .module('mw.students-config', [
    require('hapara-ng/src/experiments/experiments.service'),
  ])
  .directive('mwStudentsConfig', () => {
    return {
      restrict: 'E',
      scope: false,
      link(scope) {

        scope.$watch('config.updating', (newValue, oldValue) => {
          if (!newValue && oldValue && !scope.config.errored) {
            scope.students_form.$setPristine()
          }
        })
      },
      template: require('./students-config.html'),
    }
  })
  .name
