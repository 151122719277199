const angular = require('angular')
const _ = require('lodash')

module.exports = angular
  .module('mw.timezone-config', [
    require('./countries.service'),
  ])
  .directive('mwTimezoneConfig', (
    CountriesService,
  ) => {
    return {
      restrict: 'E',
      scope: false,
      link (scope) {
        angular.extend(scope, {
          countries: _.cloneDeep(CountriesService.countries),
          updateTimezones () {
            const countryCode = _.get(scope, 'config.Prefs.domain_country_code')
            scope.timezones = CountriesService.getTimezones(countryCode)

            const currentTz = _.get(scope, 'config.Prefs.domain_timezone')
            const exists = _.find(scope.timezones, { id: currentTz, })
            if (!exists) {
              const tz = _.get(scope, 'timezones.0.id')
              _.set(scope, 'config.Prefs.domain_timezone', tz)
            }
          },
        })

        scope.updateTimezones()
        scope.$watch('config.updating', (newValue, oldValue) => {
          if (!newValue && oldValue && !scope.config.errored) {
            scope.domain_timezone_form.$setPristine()
          }
        })
      },
      template: require('./timezone-config.html'),
    }
  })
  .name
