const angular = require('angular')
const _ = require('lodash')
const { validate, validate_and_flatten, concat } = require('../util')

module.exports = angular
  .module('mw.sis-config.directive', [
    'gettext',
    require('angular-ui-notification'),
    require('angular-ui-router'),
    require('../service/domain.service'),
    require('../service/pref.service'),
    require('./confirm-dialog.service'),
    require('./sis-options/sis-clever.directive'),
    require('./sis-options/sis-power-school.directive'),
    require('./sis-options/sis-class-link.directive'),
    require('./sis-options/sis-ric-one.directive'),
    require('./sis-clear-input.directive'),
  ])
  .directive('sisConfig', ($rootScope, $window, gettextCatalog, confirmDialog) => {
    return {
      restrict: 'E',
      template: require('./sis-config.html'),
      link: (scope, element) => {
        scope.element = element[0]

        scope.resetTestSync = () => {
          scope.test_sync_sheet_url = false
        }

        $window.onbeforeunload = event => {
          event = event || $window.event || {}

          const dirty = _.get(scope, 'form.$dirty')

          if (dirty) {
            event.returnValue = gettextCatalog.getString(
              'Your changes will be lost if you navigate away from this page without saving. Continue?'
            )
            return event.returnValue
          }
        }

        const unsubscribe = $rootScope.$on('$stateChangeStart', e => {
          const dirty = _.get(scope, 'form.$dirty')

          if (dirty) {
            const leave = confirmDialog.confirm(
              gettextCatalog.getString(
                'Your changes will be lost if you navigate away from this page without saving. Continue?'
              )
            )

            if (!leave) {
              e.preventDefault()
            }
          }
        })

        scope.$on('$destroy', () => {
          $window.onbeforeunload = undefined
          unsubscribe()
        })
      },
      controller: ($scope, $timeout, PrefService, Notification) => {
        $scope.wizard = {
          title: 'Sync Configuration',
          step_cur_index: -1,
          steps: [],
        }

        PrefService.get_config().then(function (resp) {
          $scope.config = resp.data
        })
        PrefService.get('prov_sis').then(function (resp) {
          $scope.load_year_suffix_old = resp.data.load_year_suffix.value

          $scope.data = validate(resp.data)
          $scope.$watch('data.sis_int_mode.value', val => {
            // We need to give different steps for RICONE.
            if (val === 'RICONE' || val === 'CLASS_LINK') {
              $scope.wizard.steps = [
                'SIS Provider',
                'Data Options',
                'Student Folders',
                'Calendars',
                'Student Gmails',
                'Email Notifications',
              ]
            } else {
              $scope.wizard.steps = [
                'SIS Provider',
                'Data Options',
                'Class Name',
                'Calendars',
                'Student Folders',
                'Student Gmails',
                'Email Notifications',
              ]
            }
            $scope.wizard.step_total = $scope.wizard.steps.length
          })
          $scope.data.prov_sis_folder_names.values = _.map(
            $scope.data.prov_sis_folder_names.value.split(','),
            e => ({
              text: e,
            })
          )
          $scope.data.sis_notify_addrs.values = _.map(
            $scope.data.sis_notify_addrs.value.split(','),
            e => ({
              text: e,
            })
          )

          $scope.isCurrentStepCalendars = function(type) {
            if (!type) {
              return false
            }
            return type === 'Calendars'
          }

          if (!$scope.data.ui_enable_gmail.value) {
            _.pull($scope.wizard.steps, 'Student Gmails')
          }
          $scope.wizard.step_prev = () => {
            $scope.wizard.step_cur_index =
              (--$scope.wizard.step_cur_index + $scope.wizard.step_total) % $scope.wizard.step_total
          }
          $scope.wizard.step_next = () => {
            $scope.wizard.step_cur_index = ++$scope.wizard.step_cur_index % $scope.wizard.step_total
          }
          $scope.wizard.step_resolving = false
          $scope.wizard.step_save = () => {
            if ($scope.wizard.step_resolving) return
            $scope.wizard.step_resolving = true
            $scope.data.prov_sis_folder_names.value = concat(
              _.map($scope.data.prov_sis_folder_names.values, 'text')
            )
            $scope.data.sis_notify_addrs.value = concat(
              _.map($scope.data.sis_notify_addrs.values, 'text')
            )

            var form = validate_and_flatten($scope.data)

            PrefService.save('prov_sis', form)
              .then(function () {
                $scope.wizard.step_resolving = false
                Notification.success('Saved!')
                $scope.form.$setPristine()
              })
              .catch(function () {
                $scope.wizard.step_resolving = false
              })
          }

          $scope.wizard.step_save_config = () => {
            if ($scope.wizard.step_resolving) return
            $scope.wizard.step_resolving = true

            var config = {
              integrationMethod: $scope.data.sis_int_mode.value,
              clever_district_id: $scope.config.clever_district_id,
              ricone_district_id: $scope.config.ricone_district_id,
              ps_auth_id: $scope.config.ps_auth_id,
              ps_auth_secret: $scope.config.ps_auth_secret,
              ps_host_url: $scope.config.ps_host_url,
              cl_tenant_id: $scope.config.cl_tenant_id,
              year_suffix: $scope.config.year_suffix,
            }
            PrefService.save_config(config)
              .then(function () {
                $scope.wizard.step_resolving = false
                $scope.wizard.step_next()
              })
              .catch(function () {
                $scope.wizard.step_resolving = false
                Notification.error(_.get(resp, 'data.error.message'))
              })
          }

          $scope.test_power_school_in_progress = false
          $scope.test_power_school = () => {
            if ($scope.test_power_school_in_progress) return
            $scope.test_power_school_in_progress = true
            PrefService.test_power_school({
              ps_auth_id: $scope.config.ps_auth_id,
              ps_auth_secret: $scope.config.ps_auth_secret,
              ps_host_url: $scope.config.ps_host_url,
            })
              .then(resp => {
                if (!_.get(resp, 'data.success', false)) {
                  Notification.error(_.get(resp, 'data.message'))
                } else {
                  Notification.success('Test passed!')
                }
              })
              .finally(() => {
                $scope.test_power_school_in_progress = false
              })
          }

          $scope.test_class_link_in_progress = false
          $scope.test_class_link = () => {
            if ($scope.test_class_link_in_progress) return
            $scope.test_class_link_in_progress = true
            PrefService.test_class_link({
              cl_tenant_id: $scope.config.cl_tenant_id,
            })
              .then(resp => {
                if (!_.get(resp, 'data.success', false)) {
                  Notification.error(_.get(resp, 'data.message'))
                } else {
                  Notification.success('Test passed!')
                }
              })
              .finally(() => {
                $scope.test_class_link_in_progress = false
              })
          }

          $scope.test_sync_class_in_progress = false
          $scope.test_sync_class = () => {
            if ($scope.test_sync_class_in_progress) return
            $scope.test_sync_class_in_progress = true
            $scope.test_sync_sheet_url = ''
            var config = {
              integrationMethod: $scope.data.sis_int_mode.value,
              clever_district_id: $scope.config.clever_district_id,
              ricone_district_id: $scope.config.ricone_district_id,
              ps_auth_id: $scope.config.ps_auth_id,
              ps_auth_secret: $scope.config.ps_auth_secret,
              ps_host_url: $scope.config.ps_host_url,
              cl_tenant_id: $scope.config.cl_tenant_id,
              year_suffix: $scope.config.year_suffix,
            }
            PrefService.save_config(config)
              .then(PrefService.test_sync_class)
              .then(resp => {
                var { file_id } = resp.data
                $scope.test_sync_sheet_url = `https://docs.google.com/spreadsheets/d/${file_id}`
              })
              .catch(() => {
                Notification.error('Failed to download class')
              })
              .finally(() => {
                $scope.test_sync_class_in_progress = false
              })
          }

          // folders
          $scope.folders = {
            folder_add: () => {
              $scope.data.prov_sis_folder_names.values.push({
                text: '',
              })
              $timeout(() => {
                var inputs = $scope.element.querySelectorAll('.editable-list input')
                inputs[inputs.length - 1].focus()
              })
            },
            folder_delete: f => {
              _.pull($scope.data.prov_sis_folder_names.values, f)
            },
          }
          $scope.$watch(
            () => {
              // must specify at least one folder name if option 3 is selected
              return (
                $scope.data.prov_sis_folder_option.value !==
                  $scope.map.prov_sis_folder_option_enumerated ||
                _.trim(_.map($scope.data.prov_sis_folder_names.values, 'text').join('')) !== ''
              )
            },
            val => {
              $scope.folders.error = !val
            }
          )
          // emails
          $scope.emails = {
            email_add: () => {
              $scope.data.sis_notify_addrs.values.push({
                text: '',
              })
              $timeout(() => {
                var inputs = $scope.element.querySelectorAll('.editable-list input')
                inputs[inputs.length - 1].focus()
              })
            },
            email_delete: e => {
              _.pull($scope.data.sis_notify_addrs.values, e)
            },
          }
        })
      },
    }
  }).name
