const angular = require('angular')
const _ = require('lodash')
module.exports = angular
  .module('mw.analytics-setting.directive', [])
  .directive('analyticsSetting', () => {
    return {
      restrict: 'E',
      scope: true,
      controller: ($scope, $state, $rootScope) => {
        $scope.state = $state

        $scope.wizard = {
          title: 'Analytics',
          steps: {
            'monkeywrench.base.analytics.setting.access': 'Analytics Access',
            'monkeywrench.base.analytics.setting.load-ou': 'Load OUs',
            'monkeywrench.base.analytics.setting.load-teacher-csv':
              'Load Teachers from a CSV',
            'monkeywrench.base.analytics.setting.load-student-csv':
              'Load Students from a CSV',
          },
        }
        var dashboard_enabled = _.get($rootScope, 'mw.dashboard_enabled.value')
        if (dashboard_enabled) {
          delete $scope.wizard.steps[
            'monkeywrench.base.analytics.setting.load-ou'
          ]
          delete $scope.wizard.steps[
            'monkeywrench.base.analytics.setting.load-teacher-csv'
          ]
        }
        var advanced_analytics_enabled = _.get(
          $rootScope,
          'mw.advanced_analytics_enabled.value'
        )
        if (dashboard_enabled && !advanced_analytics_enabled) {
          delete $scope.wizard.steps[
            'monkeywrench.base.analytics.setting.load-student-csv'
          ]
        }
      },
      template: require('./analytics-setting.html'),
    }
  }).name
