const angular = require('angular')
const _ = require('lodash')
module.exports = angular
  .module('mw.step-indicator.directive', [])
  .directive('stepIndicator', () => {
    return {
      restrict: 'E',
      scope: {
        steps: '=',
        curr: '=',
      },
      template: require('./step-indicator.html'),
      controller: ($scope) => {
        const rebuild = () => {
          $scope.arr = _.range($scope.steps)
        }
        $scope.$watch('steps', rebuild)
        $scope.$watch('curr', rebuild)

        // $scope.go = (step) => {
        //   if (step < $scope.curr) {
        //     $scope.curr = step
        //   }
        // }
      },
    }
  })
  .name
