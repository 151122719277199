const angular = require('angular')
const { map } = require('./optionsmap')
// const pkg = require('json-loader!../../package.json')

module.exports = angular
  .module('mw.base.controller', [
    require('hapara-ng/src/feedback/feedback.directive'),
    require('hapara-ng/src/experiments/experiments.service'),
    require('hapara-ng/src/support-includes'),
  ])
  .controller(
    'BaseController',
    ($q, $scope, $rootScope, SupportService, userFactoryGoogle, Raygun) => {
      const user = userFactoryGoogle.getUser() || {}
      Raygun.setUser(user.email)
      SupportService.init()
      angular.extend($scope, {
        showFeedback: false,
        version: '0.12.0',
      })
      // import options map
      $rootScope.map = map
    }
  ).name
