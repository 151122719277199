const angular = require('angular')

module.exports = angular
  .module('mw.configuration', [
    require('angular-ui-router'),
    require('../base-states'),
    require('./config-optional.directive'),
  ])
  .config((
    $stateProvider
  ) => {
    $stateProvider
      .state('monkeywrench.base.configuration', {
        abstract: true,
        url: '/config',
        views: {
          'body': {
            template: '<div ui-view="shell@"></div>',
          },
        },
      })
      .state('monkeywrench.base.configuration.optional', {
        url: '/optional',
        views: {
          'shell@': {
            template: '<mw-config-optional class="mw-config-optional"></mw-config-optional>',
          },
          'category@': {
            template: '<mw-config-index></mw-config-index>',
          },
        },
      })
      .state('monkeywrench.base.configuration.optional.timezone', {
        url: '/timezone',
        views: {
          'category@': {
            template: '<mw-timezone-config ng-if="config.loaded"></mw-timezone-config>',
          },
        },
      })
      .state('monkeywrench.base.configuration.optional.documents', {
        url: '/documents',
        views: {
          'category@': {
            template: '<mw-documents-config ng-if="config.loaded"></mw-documents-config>',
          },
        },
      })
      .state('monkeywrench.base.configuration.optional.classes', {
        url: '/classes',
        views: {
          'category@': {
            template: '<mw-classes-config ng-if="config.loaded"></mw-classes-config>',
          },
        },
      })
      .state('monkeywrench.base.configuration.optional.passwords', {
        url: '/passwords',
        views: {
          'category@': {
            template: '<mw-students-config ng-if="config.loaded"></mw-students-config>',
          },
        },
      })
      .state('monkeywrench.base.configuration.optional.students', {
        url: '/students',
        views: {
          'category@': {
            template: '<mw-students-config ng-if="config.loaded"></mw-students-config>',
          },
        },
      })
      .state('monkeywrench.base.configuration.optional.email', {
        url: '/email',
        views: {
          'category@': {
            template: '<mw-email-config ng-if="config.loaded"></mw-email-config>',
          },
        },
      })
      .state('monkeywrench.base.configuration.optional.allclasses', {
        url: '/allclasses',
        views: {
          'category@': {
            template: '<mw-allclasses-config ng-if="config.loaded"></mw-allclasses-config>',
          },
        },
      })
  })
  .name
