/* eslint-disable angular/no-service-method */

const angular = require('angular')

module.exports = angular
  .module('hapara.shared.gainsight', [
    'angulartics',
    require('angular-ui-router'),
    require('../class-service/tdo-server.service'),
    require('../experiments/experiments.service'),
  ])
  .service('HaparaSharedGainSightService', BigQueryService)
  .service('BigQueryService', BigQueryService).name

function BigQueryService(
  $analytics,
  $q,
  $http,
  tdoServer,
  $stateParams,
  $window,
  $rootScope,
  userFactoryGoogle
) {
  return {
    send(params) {
      let { developerMode } = $analytics.settings
      //developerMode = false

      let eventWhiteList = [
        'TD:MyClasses.viewed',
        'TD:ClassInfo.viewed',
        'TD:stayed.in.folder',
        'TD:gmail.viewed',
        'TD:smartshare.ok',
        'TD:sites.viewed',
        'HL:activity.viewed',
        'HL:tabs.viewed',
        'HL:screens.viewed',
        'HL:snaps.viewed',
        // 'HL:send.msg',
        // 'HL:open.tabs',
        'WS:workspace.created',
        'WS:workspace.publish',
        'WS:workspace.share',
        'WS:workspace.addcoteacher',
        'WS:add-learners',
        'WS:workspace.unpublish',
        'WS:workspace.archive',
        'WS:add-class',
        'WS:add-group',
        'WS:return-evidence',
        'WS:return-evidence-final',
        'WS:add-card-by-teacher',
        'WS:add-artifact-by-teacher',
        'WS:board-activity.viewed',
        'WS:board-groups.viewed',
        'WS:boards.viewed',
        'WS:individual-activity.viewed',
        'WS:create-workspace.viewed',
        'WS:search.viewed',
        'WS:show-board.viewed',
        'HL:msg.class', // from eventBundle object
        'HL:msg.group', // from eventBundle object
        'HL:msg.single', // from eventBundle object
        'HL:link.class', // from eventBundle object
        'HL:link.group', // from eventBundle object
        'HL:link.single', // from eventBundle object
        'HL:focus.class', // from eventBundle object
        'HL:focus.group', // from eventBundle object
        'HL:focus.single', // from eventBundle object
        'TD:blogger.viewed', // from eventBundle object
        'TD:blogger.comments.viewed', // from eventBundle object
        'HL:scheduleSession.shareLinks.clicked',
        'HL:scheduleSession.focusedSession.clicked',
        'HL:scheduleSession.filterSession.clicked',
        'HL:scheduleSession.sessionEdited',
        'HL:scheduleSession.sessionDeleted',
        'HL:scheduleSession.sessionDuplicated',
        'HL:sessionTemplate.selected',
        'HL:sessionTemplate.saved',
      ]

      let eventBundle = {
        'HL:msg.class': 'HL:send.msg',
        'HL:msg.group': 'HL:send.msg',
        'HL:msg.single': 'HL:send.msg',
        'HL:link.class': 'HL:open.tabs',
        'HL:link.group': 'HL:open.tabs',
        'HL:link.single': 'HL:open.tabs',
        'HL:focus.class': 'HL:focus.browsing',
        'HL:focus.group': 'HL:focus.browsing',
        'HL:focus.single': 'HL:focus.browsing',
        'TD:blogger.viewed': 'TD:blogger.viewed',
        'TD:blogger.comments.viewed': 'TD:blogger.viewed',
      }

      if (developerMode) {
        return $q.when()
      } else {
        const url = tdoServer('_ah/api/hapara/v1/userevent')

        // Add current module name to event if there is a [mod] tag to replace, e.g. TD or HL
        if (
          params &&
          params.event_name &&
          params.event_name.indexOf('[mod]') === 0
        ) {
          params.event_name = params.event_name.replace(
            '[mod]',
            $rootScope.moduleName
          )
        }

        if (params.addAuthorization) {
          userFactoryGoogle.getHeader().then(header => {
            return $http.post(
              url,
              angular.extend(
                {
                  dash_id: $stateParams.classId,
                },
                params
              ),
              {
                headers: { authorization: header },
              }
            )
          })
        } else {
          return $http.post(
            url,
            angular.extend(
              {
                dash_id: $stateParams.classId,
              },
              params
            )
          )
        }
      }
    },
  }
}

/* eslint-enable angular/no-service-method */
