const angular = require('angular')

require('angular-off-click')
module.exports = angular
  .module('hap.feedback', [
    'gettext',
    'offClick',
    require('angular-ui-notification'),
    require('angular-ui-router'),
    require('ng-device-detector'),
    require('./feedback.service'),
  ])
  .directive(
    'feedback',
    ($window, gettextCatalog, deviceDetector, $state, $timeout, Notification, FeedbackService) => {
      return {
        restrict: 'E',
        scope: true,
        link(scope, el, attr) {
          const clamp = attr.hasOwnProperty('clampOpen')
          angular.extend(scope, {
            feedbackState: {
              content: '',
              rows: clamp ? 3 : 1,
              sending: false,
              sent: false,
            },
            close() {
              if (!scope.feedback.form.$valid && !clamp) {
                scope.feedbackState.rows = 1
              }
            },
            open() {
              scope.feedbackState.sent = false
              scope.feedbackState.rows = 3
              $timeout(() => el[0].scrollIntoView())
            },
            reset() {
              scope.feedbackState.sent = false
            },
            send() {
              if (scope.feedback.form.$invalid) {
                return
              }

              const { availHeight, availWidth } = $window.screen
              const { innerHeight, innerWidth } = $window
              const { browser, os } = deviceDetector

              scope.feedbackState.sending = true
              FeedbackService.send({
                body: scope.feedbackState.content,
                browser: `${browser} ${deviceDetector.browser_version}`,
                product: attr.product,
                frontendVersion: attr.version,
                os: os,
                pageResolution: `${innerWidth}x${innerHeight}`,
                screenResolution: `${availWidth}x${availHeight}`,
                clientDatetime: new Date().toISOString(),
                url: $window.location.href,
                pageState: $state.current.name,
                userType: attr.userType,
              })
                .then(() => {
                  scope.feedbackState = {
                    content: '',
                    rows: clamp ? 3 : 1,
                    sending: false,
                    sent: true,
                  }

                  $timeout(scope.reset, 5 * 1000)
                })
                .catch(() => {
                  Notification.error(
                    gettextCatalog.getString('Uh oh! Something went wrong sending your feedback.')
                  )
                })
                .finally(() => {
                  scope.feedbackState.sending = false
                })
            },
          })
        },
        template: require('./feedback.html'),
      }
    }
  ).name
