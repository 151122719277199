const angular = require('angular')
const _ = require('lodash')
const hapara_header_logo = require('hapara-assets/src/images/logo-hapara-light.svg')

module.exports = angular
  .module('mw.self-prov.header.directive', [
    require('hapara-ng/src/experiments/experiments.service'),
  ])
  .directive('mwSelfProvHeader', () => {
    return {
      restrict: 'E',
      template: require('./header.html'),
      controller: ($scope, userFactoryGoogle) => {
        $scope.hapara_header_logo = hapara_header_logo

        $scope.$watch(
          () => userFactoryGoogle.user,
          () => {
            $scope.username = _.get(userFactoryGoogle.getUser(), 'email', 'Logging...')
          }
        )
      },
    }
  }).name
