const angular = require('angular')

module.exports = angular
  .module('mw.is-google-classable', [
    require('angular-ui-router'),
    require('../config.service'),
  ])
  .directive('isGoogleClassable', ($q, $stateParams, MwConfigService) => {
    return {
      restrict: 'A',
      require: 'ngModel',
      link(scope, el, attr, ngModel) {
        ngModel.$asyncValidators.isGoogleClassable = modelValue => {
          if (!ngModel.$modelValue && modelValue) {
            const check = MwConfigService.checkGoogleClassable(
              $stateParams.domain
            )

            check.catch(() => {
              if (ngModel.classableFailed) {
                ngModel.hardClassableFailed = true
              }
              ngModel.classableFailed = true
            })
            return check
          } else {
            return $q.when()
          }
        }
      },
    }
  }).name
