const angular = require('angular')

module.exports = angular
  .module('utils.auto-focus', [])
  .directive('autoFocus', (
    $timeout,
  ) => {
    return {
      restrict: 'A',
      scope: false,
      link (scope, element) {
        $timeout(() => element[0] && element[0].focus())
      },
    }
  })
  .name
