const angular = require('angular')

module.exports = angular
  .module('mw.home', [
    require('angular-ui-router'),
    require('../base-states'),
    require('./home.directive'),
    require('angular-ui-bootstrap/src/modal/index-nocss.js'),
    require('../service/reauthorization.service'),
  ])
  .config($stateProvider => {
    $stateProvider
      .state('monkeywrench.base.home', {
        abstract: true,
        url: '/home',
        views: {
          body: {
            template: '<div ui-view><loader-index/></div>',
          },
        },
        data: {
          greyBackGround: true,
        },
      })
      .state('monkeywrench.base.home.index', {
        url: '/index',
        template: '<mw-home></mw-home>',
      })
  }).name
