const angular = require('angular')
module.exports = angular
  .module('mw.sub-menu.directive', [])
  .directive('subMenu', () => {
    return {
      restrict: 'E',
      scope: {
        wizard: '=',
      },
      template: require('./sub-menu.html'),
    }
  }).name
