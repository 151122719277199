const angular = require('angular')
const _ = require('lodash')

module.exports = angular
  .module('mw.domain-audit.directive', [
    require('../service/domain.service'),
    require('hapara-ng/src/hap-icon/hap-icon.directive'),
    require('hapara-ng/src/pugs/pug-phone.service'),
  ])
  .directive('domainAudit', ($http, PugPhone, DomainService) => {
    return {
      scope: true,
      link(scope) {
        // Show loading icons until the data lands
        scope.loading = true
        const domain = _.trimStart(DomainService.get_domain(), '-') // TODO move this to a utility function if we need to use it elsewhere.
        const url = PugPhone.dial(`/v2/tools/auditinfo/${domain}`)
        $http.get(url).then((response = {}) => {
          scope.loading = false
          scope.auditData = response.data
        })
      },
      template: require('./domain-audit.html'),
    }
  }).name
