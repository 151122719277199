const angular = require('angular')
const _ = require('lodash')

const { version } = require('../config')
require('raygun4js')

module.exports = angular
  .module('mw.error-service', [require('./error-reporter.service')])
  .constant('Raygun', window.Raygun)
  .run(Raygun => {
    const options = {
      excludedHostnames: ['0.0.0.0', 'localhost'],
      ignore3rdPartyErrors: true,
      disablePulse: true,
    }

    Raygun.init('z4fgbHNLZU8lX7VYcXowdQ==', options).attach()

    Raygun.setVersion(version)
  })
  .factory('ErrorService', function(
    $log,
    $window,
    $rootScope,
    env,
    ErrorReporter,
    Raygun
  ) {
    $window.onerror = ErrorReporter.report

    $rootScope.ErrorBuckets = {}

    /**
     * Only consider the 'data' property of errors from $http,
     * but keep the status.
     */
    function normalise(e = {}) {
      const { data, config = {} } = e
      const _data = _(data)

      if (_data.isObject()) {
        return _data
          .defaults({
            method: config.method,
            status: e.status,
            url: config.url,
          })
          .value()
      } else {
        return {
          message: _data.value(),
          status: e.status,
        }
      }
    }

    /**
     * Returns true if 502, 504, or 4XX
     * @param {Integer} status
     */
    function raygunnable(status = 0) {
      return status === 502 || status === 504 || Math.floor(status / 100) === 4
    }

    const $ES = {
      forBucket(key) {
        return e => {
          e = normalise(e)
          $ES.onError(e)
          $rootScope.ErrorBuckets[key] = e
        }
      },
      /**
       * The ErrorService is for errors from XHR requests.
       * It will send to Raygun iff:
       *  * There's a message
       *  * The `env` isn't development
       *  * The status is 502, 504, or 4XX
       *
       * @param {Error} e
       */
      onError(e = {}) {
        const { message, status } = e

        const send = message && env === 'production' && raygunnable(status)

        if (send) {
          return Raygun.send(new Error(message), e, ['backend', env])
        } else {
          return $log.error(e)
        }
      },
    }

    return $ES
  })
  .factory('$exceptionHandler', ErrorReporter => {
    return ErrorReporter.report
  }).name
