const angular = require('angular')

module.exports = angular.module('mw.util', [])
  .directive('autofocus', function ($timeout) {
    return {
      restrict: 'A',
      link: function ($scope, $element) {
        $timeout(function () {
          $element[0].focus()
        })
      },
    }
  })
  .name
