const angular = require('angular')

module.exports = angular
  .module('sis-clever.directive', [])
  .directive('sisClever', () => {
    return {
      scope: false,
      template: require('./clever.html'),
    }
  })
  .name
