const angular = require('angular')
const _ = require('lodash')
const CONFIG = require('../config')

require('../../assets/csv/teacher_analytics_enabled.csv')
require('../../assets/csv/teacher_advanced_analytics_enabled.csv')
require('../../assets/csv/student_analytics_enabled.csv')
require('../../assets/csv/student_advanced_analytics_enabled.csv')

module.exports = angular
  .module('mw.analytics', [
    require('./analytics-index.directive'),
    require('./analytics-access.directive'),
    require('./analytics-setting.directive'),
    require('./analytics-load-ou.directive'),
    require('./analytics-load-teacher-csv.directive'),
    require('./analytics-load-student-csv.directive'),
  ])
  .run(($rootScope, $state) => {
    $rootScope.$on('$stateChangeStart', function(event, toState, toParam) {
      if (toState.name === 'monkeywrench.base.analytics') {
        event.preventDefault()
        $state.go('monkeywrench.base.analytics.index', toParam)
      }
    })
  })
  .config($stateProvider => {
    $stateProvider
      .state('monkeywrench.base.analytics', {
        // abstract: true,
        url: '/analytics',
        views: {
          body: {
            template: '<div ui-view></div>',
            controller: function($state, $rootScope, $window, DomainService) {
              if (!_.get($rootScope, 'mw.analytics_enabled.value', false)) {
                $window.location.href =
                  CONFIG.API_ROOT + '/' + DomainService.get_domain()
                return
              }
            },
          },
        },
      })
      .state('monkeywrench.base.analytics.index', {
        url: '/index',
        template: '<analytics-index/>',
      })
      .state('monkeywrench.base.analytics.setting', {
        url: '/setting',
        template: '<analytics-setting/>',
      })
      .state('monkeywrench.base.analytics.setting.access', {
        url: '/access',
        template: '<analytics-access/>',
      })
      .state('monkeywrench.base.analytics.setting.load-ou', {
        url: '/load-ou',
        template: '<analytics-load-ou/>',
      })
      .state('monkeywrench.base.analytics.setting.load-teacher-csv', {
        url: '/load-teacher-csv',
        template: '<analytics-load-teacher-csv/>',
      })
      .state('monkeywrench.base.analytics.setting.load-student-csv', {
        url: '/load-student-csv',
        template: '<analytics-load-student-csv/>',
      })
  }).name
