const angular = require('angular')
const _ = require('lodash')
module.exports = angular
  .module('mw.http', ['mw.google'])
  .factory('authHttpInterceptor', function authHttpInterceptor($q, $injector) {
    let userFactoryGoogle

    return {
      request: async config => {
        userFactoryGoogle = userFactoryGoogle || $injector.get('userFactoryGoogle')

        var { token, userEmail } = await userFactoryGoogle.getAuth()
        if (token && userEmail) {
          _.set(config, 'headers.authorization', `Bearer ${token}`)
          _.set(config, 'headers.user', userEmail)
        }
        return config
      },
      responseError: rejection => {
        const $state = $injector.get('$state')
        if (rejection.status === 401) {
          $state.go('monkeywrench.login')
        } else if (
          rejection.status === 403 &&
          !rejection.config.url.endsWith('/pugs/user/features') &&
          !rejection.config.url.includes('/v2/pugs/workgroup/list/search')
        ) {
          // /pugs/user/features could return 403 error if it's a Hapara user
          $state.go('monkeywrench.forbidden')
        }
        return $q.reject(rejection)
      },
    }
  })
  .config($httpProvider => {
    $httpProvider.interceptors.push('authHttpInterceptor')
  }).name
