const angular = require('angular')
const _ = require('lodash')
const CONFIG = require('../config')

require('angular-ui-bootstrap/src/pagination/index')
module.exports = angular
  .module('mw.status.directive', [
    require('angular-ui-router'),
    require('../google'),
    require('../service/domain.service'),
    require('../service/pref.service'),
    require('angular-sanitize'),
    require('angular-ui-notification'),
    require('hapara-ng/src/experiments/experiments.service'),
    'angularMoment',
    'ui.bootstrap.module.pagination',
  ])
  .directive('status', () => {
    return {
      restrict: 'E',
      template: require('./status.html'),
      link: (scope, element) => {
        scope.detail_table_element = angular.element(element.find('table')[1])
      },
      controller: (
        $scope,
        $q,
        $state,
        $http,
        $stateParams,
        $rootScope,
        $document,
        $timeout,
        PrefService,
        userFactoryGoogle,
        DomainService,
        Notification,
        $uibModal,
        PugPhone
      ) => {
        angular.extend($scope, {
          loading: false,
          loading_details: false,
          details_filter: {
            value: '1',
          },
          task_sort: {
            sidx: 'created_dt',
            sord: 'desc',
          },
          task_sort_change(field) {
            if ($scope.task_sort.sidx === field) {
              if ($scope.task_sort.sord === 'desc') {
                $scope.task_sort.sord = 'asc'
              } else {
                $scope.task_sort.sord = 'desc'
              }
            } else {
              $scope.task_sort.sidx = field
            }
            $scope.tasks_go(1)
          },
          detail_sort: {
            sidx: 'created_dt',
            sord: 'asc',
          },
          detail_sort_change(field) {
            if ($scope.detail_sort.sidx === field) {
              if ($scope.detail_sort.sord === 'desc') {
                $scope.detail_sort.sord = 'asc'
              } else {
                $scope.detail_sort.sord = 'desc'
              }
            } else {
              $scope.detail_sort.sidx = field
            }
            $scope.details_go(1)
          },
          tasks_go(page) {
            $scope.loading = true
            PrefService.get_tasks(page, 10, $scope.task_sort)
              .then(resp => {
                $scope.data = resp.data
              })
              .finally(() => {
                $scope.loading = false
              })
          },
          use_error_database(details) {
            const { get_domain, get_nid } = DomainService
            const errorCodes = _.uniq(
              _.map(details.rows, 'op_error_code')
            ).join(',')
            const nid = get_nid()
            const errorDBUrl = `https://td-admin.appspot.com/api?cmd=getErrorDetails&domain=${get_domain()}&error_ids=${errorCodes}${
              nid ? `&nid=${nid}` : ''
            }`
            // Call the server to get errors from the database.
            $http.get(errorDBUrl).then(function(resp) {
              // Try and parse the json if we get nothing return and stick with the errors the server gave us.
              let errorDB = {}
              try {
                errorDB = JSON.parse(resp.data.data)
              } catch (e) {
                return false
              }
              // foreach details.rows if op_code is in errorDB replace the message.
              details.rows.forEach(function(element) {
                if (!element.op_error_code) {
                  return
                }
                const inDb = _.find(errorDB, {
                  code: element.op_error_code.toLowerCase(),
                })
                // Apply support link to object
                if (inDb) {
                  element.link = inDb.link
                  let replaceJson = {}
                  let strReplacement = inDb.error_message
                  try {
                    replaceJson = JSON.parse(element.op_error_context)
                    _.forEach(replaceJson, function(obj, key) {
                      // key is the value to replace
                      // obj is the value it's replaced by
                      strReplacement = strReplacement.replace(
                        new RegExp(`{${key}}`, 'g'),
                        obj
                      )
                    })
                  } catch (e) {
                    return false
                  } finally {
                    element.op_msg = strReplacement
                  }
                }
              })
            })
          },
          get_details(task) {
            $scope.currentTask = _.clone(task)
            $scope.load_id = task.load_id
            $scope.selected_id = task.load_id
            if (
              (task.errored_proc > 0 || task.errors > 0) &&
              (task.is_completed || task.has_failed)
            ) {
              $scope.show_refresh = true
            } else {
              $scope.show_refresh = false
            }
            $scope.domain = DomainService.get_domain_without_dash()
            $scope.details_go(1)
            $timeout(() => {
              $document.scrollTo($scope.detail_table_element, 0, 500)
            })
          },
          details_go(page) {
            $scope.loading_details = true
            $scope.process_refreshed = false
            PrefService.get_task_details(
              $scope.load_id,
              page,
              30,
              $scope.details_filter.value,
              $scope.detail_sort
            )
              .then(resp => {
                $scope.details = resp.data
                // Clone details because we are going to change it with the error database
                $scope.currentDetails = _.clone($scope.details)
                $scope.use_error_database($scope.currentDetails)
              })
              .finally(() => {
                $scope.loading_details = false
              })
          },
          filter() {
            $scope.details_go(1)
          },
          refresh() {
            $scope.tasks_go(1)
          },
          refresh_processes(domain, load_id) {
            $http
              .get(
                `${
                  CONFIG.API_ROOT
                }/api?cmd=rerunfailedprocs&format=extjs&domain=${domain}&load_id=${load_id}`
              )
              .then(() => {
                $scope.process_refreshed = true
                $scope.refresh()
              })
              .catch(() => {
                Notification.error({
                  title: 'Could not refresh',
                  message:
                    'If problems persist, please contact <a  href="https://support.hapara.com/" target="_blank">Hapara Support</a>',
                  delay: false,
                  replaceMessage: true,
                })
              })
          },
          sendErrors(error) {
            $scope.errorToSend = _.clone(error)
            var modalInstance = $uibModal.open({
              scope: $scope,
              controller: [
                '$scope',
                scope => {
                  scope.logATicket = false
                  scope.sendReport = () => {
                    const payload = {
                      loadID: $scope.errorToSend.load_id,
                      docID: $scope.errorToSend.doc_id,
                      userMsg: $scope.errorToSend.message,
                      format: $scope.errorToSend.format,
                      reportID: $scope.errorToSend.report_id,
                      filename: $scope.errorToSend.filename,
                      domain: _.trimStart($stateParams.domain, '-'),
                      label: $scope.errorToSend.label,
                      description: $scope.errorToSend.description,
                    }
                    $http
                      .post(
                        PugPhone.dial(`/v2/services/support/dataload`),
                        payload
                      )
                      .then(() => {
                        scope.modalSendComplete = true
                      })
                      .catch(() => {
                        Notification.error({
                          title: 'Could not send error report',
                          message:
                            'If problems persist, please contact <a  href="https://support.hapara.com/" target="_blank">Hapara Support</a>',
                          delay: false,
                          replaceMessage: true,
                        })
                      })
                  }
                  scope.sendReportClose = () => {
                    modalInstance.close()
                  }
                },
              ],
              template: require('./status-report-error-modal.html'),
            })
          },
        })

        $scope.tasks_go(1)
      },
    }
  }).name
