const angular = require('angular')

module.exports = angular
  .module('mw.loader-index.directive', [require('hapara-ng/src/hapara-nav/hapara-url.service')])
  .directive('loaderIndex', HaparaUrlService => {
    return {
      restrict: 'E',
      scope: true,
      link(scope) {
        angular.extend(scope, {
          manageClassesHref: HaparaUrlService.url('td.manage-classes'),
        })
      },
      template: require('./loader-index.html'),
    }
  }).name
