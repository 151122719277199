const angular = require('angular')
const _ = require('lodash')
module.exports = angular
  .module('mw.analytics-access.directive', [])
  .directive('analyticsAccess', () => {
    return {
      restrict: 'E',
      scope: true,
      controller: ($scope, $state, PrefService, $rootScope, Notification) => {
        // button enabled?
        $scope.error = false

        PrefService.validate_google_group()
          .then(res => {
            $scope.result = res.data.ValidationReport

            // sync google group
            $scope.syncing = false
            $scope.sync = () => {
              $scope.syncing = true
              PrefService.sync_google_group()
                .then(() => {
                  Notification.success('Succeeded!')
                })
                .catch(resp => {
                  var msg = _.get(resp, 'data.Message')
                  Notification.error(msg || 'Failed!')
                })
                .finally(() => {
                  $scope.syncing = false
                })
            }
          })
          .catch(() => {
            $scope.error = true
          })
      },
      template: require('./analytics-access.html'),
    }
  }).name
