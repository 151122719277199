const angular = require('angular')
const _ = require('lodash')

module.exports = angular
  .module('hapara.shared.bus', [])
  .factory('haparaClientBus', HaparaClientBus).name

function HaparaClientBus($log, $cacheFactory, $timeout) {
  const cache = $cacheFactory('SparkBus', {
    capacity: 10000,
  })

  const commandHandlers = {}

  const recording = []
  let recordingActive = false

  const SB = {
    /**
     * gets an item from the bus cache - if we want to get the last item emitted
     * onto a key.
     *
     * @param key
     * @returns {*}
     */
    get: key => {
      return cache.get(key)
    },
    /**
     * This is weird behaviour, it should remove it not null it.
     * @param key
     * @returns {*}
     */
    clear: key => {
      return SB.put(key, null)
    },
    put: (key, data) => {
      return cache.put(key, _.isUndefined(data) ? null : data) // undefined doesn't get stored!
    },

    /**
     * get the recorded items. interface for testing, we can turn recording on
     * and all incoming messages will be put in here. It should only be used as such otherwise
     * it is a big memory leak, and it doesn't stop the bus from working.
     */
    recordings: () => {
      return recording
    },

    resetRecording: () => {
      recording.length = 0
    },

    enableRecording: () => {
      recordingActive = true
    },

    disableRecording: () => {
      recordingActive = false
    },

    /**
     * put the data in the cache with the key and then broadcast to all handlers.
     *
     * @param key
     * @param data
     */
    emit: (key, data) => {
      const handlers = _.clone(commandHandlers[key] || [])

      SB.put(key, data)

      if (recordingActive) {
        recording.push({ key: key, data: data })
      }

      _.each(handlers, handler => {
        SB.handle(key, data, handler)
      })
    },
    /**
     * This emits a whole bunch of commands in one go, often as a multiple disparate collection of actions
     * that relate to incoming traffic. It is the old "handle" method that dealt with both arrays of data
     * and single commands.
     *
     * @param bundle
     */
    emitBundle: bundle => {
      if (angular.isArray(bundle)) {
        angular.forEach(bundle, command => {
          if (command.command) {
            SB.emit(command.command, command.data)
          } else if (command.key) {
            SB.emit(command.key, command.data)
          }
        })
      }
    },
    /*
    * synchronously deliver this message (i.e. call and wait in the same
    * digest cycle).Synonym for emit - use this in preference to distinguish from async.
    */
    sync: (key, data) => {
      SB.emit(key, data)
    },
    /*
    * asynchronously deliver the message (different digest cycle)
     */
    async: (key, data) => {
      $timeout(function() {
        SB.emit(key, data)
      }, 1)
    },

    handle: (key, data, handler) => {
      try {
        handler.call(
          {
            deregister: () => {
              SB.unregister(key, handler)
            },
            key: key,
            handler: handler,
          },
          data
        )
      } catch (e) {
        $log.error(e)
      }
    },
    handleMulti: (trigger, keys, handler) => {
      const result = _.map(keys, key => cache.get(key))

      handler.apply(
        {
          keys: keys,
          handler: handler,
          triggerKey: trigger,
        },
        result
      )
    },
    multi: (opts, handler) => {
      const { keys, scope } = opts

      if (!_.isFunction(handler)) {
        return $log.error(
          'Attempted to register with an invalid handler %O',
          handler
        )
      }

      _.each(keys, key => {
        SB.register(
          {
            key: key,
            scope: scope,
            silent: true,
          },
          () => SB.handleMulti(key, keys, handler)
        )
      })

      SB.handleMulti(undefined, keys, handler)
    },
    /*
     * historical handler registration for a directive
     */
    registerScopeHandler: (scope, key, handler) => {
      SB.register({ scope: scope, key: key, silent: true }, handler)
    },
    /*
     * historical for a service or factory
     */
    registerCommandHandler: (key, handler) => {
      SB.register({ scope: null, key: key, silent: true }, handler)
    },
    /**
     * Register a listener.
     *
     * @param {Object} opts
     * @param {String} opts.key to listen for
     * @param {Scope} [opts.scope] if scope is destroyed, deregister this listener
     * @param {Boolean} [opts.awaitEmit] iff is `true` do not run handler immediately
     * @param {Boolean} [opts.silent] iff is `true` do not run handler immediately (alias for `awaitEmit`) @deprecated
     * @return {Function} deregister
     */
    register(opts, handler) {
      const { key, scope } = opts

      const awaitEmit = opts.awaitEmit || opts.silent // accept either parameter

      if (!_.isFunction(handler)) {
        return $log.error(
          'Attempted to register with an invalid handler %O',
          handler
        )
      }

      function deregister() {
        return SB.unregister(key, handler)
      }

      if (scope) {
        scope.$on('$destroy', deregister)
      }

      commandHandlers[key] = commandHandlers[key] || []
      commandHandlers[key].push(handler)

      if (!awaitEmit) {
        SB.handle(key, cache.get(key), handler)
      }

      return deregister
    },
    unregister: (key, handler) => {
      const handlers = commandHandlers[key] || []

      const result = _.pull(handlers, handler) // mutates `handlers`

      if (result.length === 0) {
        delete commandHandlers[key]
      }

      return result
    },
  }

  return SB
}
