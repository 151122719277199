const angular = require('angular')
const CONFIG = require('../config')
const hapara_logo_new = require('../../assets/logo-hapara-small.svg')
const ReauthModal = require('./reauth-modal.js')

module.exports = angular
  .module('mw.home.directive', [
    require('angular-ui-router'),
    require('angular-ui-bootstrap'),
    require('hapara-ng/src/experiments/experiments.service'),
  ])
  .directive('mwHome', ($stateParams, ExperimentsService) => {
    return {
      scope: true,
      restrict: 'E',
      link(scope) {
        scope.td_admin_url = CONFIG.API_ROOT
        scope.teacher_dashboard_url = CONFIG.TEACHER_DASHBOARD
        scope.domain = $stateParams.domain
        scope.hapara_logo = hapara_logo_new
        scope.nid = $stateParams.nid
        scope.isAnyUsageReportFlagOn =
          ExperimentsService.getTdAdmin('HAP-10205-HAC-Embed-Usage-Reports') ||
          ExperimentsService.getTdAdmin('HAP-10369-usage-report-gen2')
      },
      template: require('./home.html'),
      controller: ($scope, $stateParams, $uibModal, ReauthorizationService) => {
        if (!$scope.isFreshLogin && document.referrer !== '') {
          return
        }
        ReauthorizationService.isAuthorized($stateParams.domain, $stateParams.nid).then(
          isAuthorized => {
            if (!isAuthorized.data.approved) {
              ReauthModal.open($uibModal, ReauthorizationService, $scope.domain, false)
            }
          }
        )
      },
    }
  }).name
