const angular = require('angular')
module.exports = angular.module('mw.allclasses-config', []).directive('mwAllclassesConfig', () => {
  return {
    restrict: 'E',
    scope: false,
    link(scope) {
      scope.$watch('config.updating', (newValue, oldValue) => {
        if (!newValue && oldValue && !scope.config.errored) {
          scope.allclasses_form.$setPristine()
        }
      })
    },
    template: require('./allclasses-config.html'),
  }
}).name
