const angular = require('angular')
const _ = require('lodash')
const CONFIG = require('../config')
module.exports = angular
  .module('mw.pref.service', ['hap.pug-phone', require('angular-resource')])
  .service('PrefService', ($http, DomainService, $resource, PugPhone) => {
    const {
      get_domain,
      get_nid,
      get_domain_nid,
      get_domain_without_dash,
    } = DomainService
    return {
      get: key => {
        return $http.get(
          CONFIG.API_ROOT + `/${get_domain_nid()}?page=${key}&format=json`
        )
      },
      save: (key, prefs) => {
        return $http.post(
          CONFIG.API_ROOT + `/${get_domain_nid()}?page=${key}&format=json`,
          prefs
        )
      },
      get_config: () => {
        return $http.get(
          CONFIG.API_SERVER_ROOT +
            `/_ah/api/sis/v1/getConfig?domain=${get_domain()}`
        )
      },
      save_config: config => {
        return $http.post(
          CONFIG.API_SERVER_ROOT + '/_ah/api/sis/v1/setConfig',
          {
            ...config,
            domain: get_domain(),
          }
        )
      },
      test_power_school: ps => {
        return $http.post(
          CONFIG.API_SERVER_ROOT + '/_ah/api/sis/v1/testPSConfig',
          {
            ...ps,
            domain: get_domain(),
          }
        )
      },
      test_class_link: cl => {
        return $http.post(
          CONFIG.API_SERVER_ROOT + '/_ah/api/sis/v1/testCLConfig',
          {
            ...cl,
            domain: get_domain(),
          }
        )
      },
      test_sync_class: () => {
        return $http.post(
          CONFIG.API_SERVER_ROOT + '/_ah/api/sis/v1/extractClasses',
          {
            upload_file: false,
            domain: get_domain(),
          }
        )
      },
      sync_class: () => {
        return $http.post(
          CONFIG.API_SERVER_ROOT + '/_ah/api/sis/v1/extractClasses',
          {
            upload_file: true,
            domain: get_domain(),
          }
        )
      },
      sync_student: () => {
        return $http.post(
          CONFIG.API_SERVER_ROOT + '/_ah/api/sis/v1/extractStudents',
          {
            domain: get_domain(),
          }
        )
      },
      find_doc: doc_name => {
        return $http.get(
          CONFIG.API_ROOT +
            `/api?domain=${get_domain_without_dash()}&nid=${get_nid()}&cmd=doclist&type=spreadsheet&term=${doc_name}`
        )
      },
      verify_doc: doc_name => {
        return $http.post(
          CONFIG.API_ROOT + `/${get_domain_nid()}?page=archiveload&format=json`,
          {
            page: 'archiveload',
            spreadsheet_archivename: doc_name,
            verify: 'Verify access to archive classes spreadsheet',
          }
        )
      },
      archive: form => {
        return $http.post(
          CONFIG.API_ROOT + `/${get_domain_nid()}?page=archiveload&format=json`,
          {
            page: 'archiveload',
            archive_label: form.archive_label,
            archiveall: '',
            docid: form.docid,
            load: 'Start archiving classes on spreadsheet',
          }
        )
      },
      archive_all: form => {
        return $http.post(
          CONFIG.API_ROOT + `/${get_domain_nid()}?page=archiveload&format=json`,
          {
            page: 'archiveload',
            archive_label: form.archive_label,
            archiveall: 'true',
          }
        )
      },
      is_archiving_running: () => {
        return $http.get(
          CONFIG.API_ROOT + `/${get_domain_nid()}?page=archiveload&format=json`,
          {
            page: 'archiveload',
          }
        )
      },
      query: search => {
        const { all, page, pageSize, siteCode, domainURN } = search
        const q = search.query
        const url = PugPhone.dial('/v2/hac/workgroups/search')
        const resource = $resource(
          url,
          {},
          {
            query: {
              method: 'GET',
              interceptor: {
                response({ data = {} } = {}) {
                  _.each(data.Groups, (group = {}) => {
                    const prefs = _.get(group, 'Prefs', {})

                    prefs.Colour = prefs.Colour || 15 // the default colour index

                    const managers = _.map(group.Managers, (manager = {}) => {
                      const { Fname, Lname } = manager

                      if (Fname || Lname) {
                        manager.FullName = `${Fname} ${Lname}`
                      } else {
                        manager.FullName = manager.PrimaryEmail
                      }

                      return manager
                    })

                    group.Managers = _.uniqBy(managers, 'PrimaryEmail')
                  })

                  return data
                },
              },
              url,
            },
          }
        )

        return resource.query({
          all,
          page,
          pageSize,
          q,
          siteCode,
          domainURN,
        })
      },
      get_tasks: (page = 1, size = 10, sort) => {
        return $http.post(
          CONFIG.API_ROOT +
            `/api?cmd=loadlist&domain=${get_domain_without_dash()}&nid=${get_nid()}&sid=EdxVpQcebf&_search=false&nd=1467596348394&rows=${size}&page=${page}&sidx=${
              sort.sidx
            }&sord=${sort.sord}`
        )
      },
      get_task_details: (loadid, page = 1, size = 10, filter = '1', sort) => {
        return $http.post(
          CONFIG.API_ROOT +
            `/api?cmd=loaddetails&domain=${get_domain_without_dash()}&nid=${get_nid()}&sid=EdxVpQcebf&loadid=${loadid}&filter=${filter}&_search=false&nd=1467668627383&rows=${size}&page=${page}&sidx=${
              sort.sidx
            }&sord=${sort.sord}`
        )
      },
      load: (docid, setting) => {
        return $http.post(
          CONFIG.API_ROOT + `/${get_domain_nid()}?page=newloadg&format=json`,
          {
            page: 'newloadg',
            docid: docid,
            opcode: 'upsert',
            load: 'Start data load',
            ...setting,
          }
        )
      },
      load_csv: (file, csv_loading_setting) => {
        var formData = new FormData()
        formData.append('uploadFile', file)
        formData.append('upload', 'Upload CSV File For Processing')
        formData.append('loadAll', csv_loading_setting.loadAll)
        formData.append('fullSync', csv_loading_setting.fullSync)
        formData.append('multipleYears', csv_loading_setting.multipleYears)

        return $http({
          url:
            CONFIG.API_ROOT + `/${get_domain_nid()}?page=csvload&format=json`,
          method: 'POST',
          data: formData,
          headers: {
            'Content-Type': undefined,
          },
        })
      },
      load_csv_for_an: (file, role) => {
        var formData = new FormData()
        formData.append('uploadFile', file)
        formData.append('usersRole', role)

        return $http({
          url:
            CONFIG.API_ROOT +
            `/${get_domain_nid()}?page=csvload_an&format=json`,
          method: 'POST',
          data: formData,
          headers: {
            'Content-Type': undefined,
          },
        })
      },
      regenerate_key: () => {
        var formData = new FormData()
        formData.append('regenerate', 'Generate A New Pass Key')

        return $http({
          url:
            CONFIG.API_ROOT + `/${get_domain_nid()}?page=csvload&format=json`,
          method: 'POST',
          data: formData,
          headers: {
            'Content-Type': undefined,
          },
        })
      },
      deleteClass: data => {
        const { classes, domainurn } = data,
          url = PugPhone.dial('/v2/hac/workgroups/batch/delete'),
          resource = $resource(
            url,
            {},
            {
              deleteClass: {
                method: 'POST',
                interceptor: {
                  response({ data = {} } = {}) {
                    return data
                  },
                },
                url,
              },
            }
          )

        return resource.deleteClass({
          classes,
          domainurn,
        })
      },
      get_sample_base_link: () => {
        return CONFIG.API_ROOT + `/${get_domain_nid()}?page=prov_man_example`
      },
      validate_google_group: domain => {
        return $http.get(
          CONFIG.API_ROOT +
            `/${domain || get_domain()}/optional/analytics-users/validate`
        )
      },
      sync_google_group: () => {
        return $http.post(
          CONFIG.API_ROOT + `/${get_domain()}/optional/analytics-users/set`
        )
      },
      verifyOU: (type, ou_path, domain) => {
        return $http.post(
          CONFIG.API_ROOT + `/${get_domain()}/optional/validateou/${type}`,
          {
            ou_path,
            domain,
          }
        )
      },
      setOU: form => {
        return $http.post(
          CONFIG.API_ROOT + `/${get_domain()}/optional/setou`,
          form
        )
      },
    }
  }).name
