const moment = require('moment')
const _ = require('lodash')
const axios = require('axios')
const jose = require('jose')

const { window } = global
let jwtToken = null
let tokenUpdatePromise = null
let isTestState = false

const TEST_TOKEN = {
  token_type: 'Bearer',
  access_token: 'xxxxxxxxx',
}
const BEARER_TOKEN_TYPE = 'Bearer'
const TEST_ENV = 'test'
const PROD_ENV = 'production'
const DEVELOPMENT_ENV = 'development'

const getCurrentEnv = () => {
  const host = window.location.hostname || '' // eslint-disable-line angular/window-service
  if (host.match(/-test/)) {
    return TEST_ENV
  } else if (host.match(/localhost/)) {
    return DEVELOPMENT_ENV
  }
  return PROD_ENV
}

const isProduction = () => {
  return getCurrentEnv() === PROD_ENV
}

const getHostUrl = () => {
  if (!isProduction()) {
    return 'https://api-test.hapara.com'
  } else {
    return 'https://api.hapara.com'
  }
}

const getHaparaJWT = async googleJWT => {
  const url = `${getHostUrl()}/auth-service/oauth/token?grant_type=refresh_token`
  const config = {
    headers: { Authorization: `${BEARER_TOKEN_TYPE} ${googleJWT}` },
    withCredentials: true,
  }
  return axios.post(url, null, config)
}

export const initHaparaJWT = async googleJWT => {
  tokenUpdatePromise = getHaparaJWT(googleJWT)
    .then(resp => {
      jwtToken = {
        access_token: _.get(resp, 'data.access_token'),
        at_expires: _.get(resp, 'data.at_expires'),
        token_type: BEARER_TOKEN_TYPE,
      }
      const expires_at = _.get(resp, 'data.at_expires') * 1000 // Unix Timestamp to Date.now() format
      localStorage.setItem('tokenExpire', expires_at)
      return jwtToken
    })
    .catch(e => {
      throw e
    })
    .finally(() => {
      tokenUpdatePromise = null
    })
  return tokenUpdatePromise
}

export const refreshHaparaJWT = async (swallowError = false) => {
  const url = `${getHostUrl()}/auth-service/oauth/token`
  const config = { withCredentials: true }

  return axios
    .post(url, null, config)
    .then(resp => {
      jwtToken = {
        access_token: _.get(resp, 'data.access_token'),
        at_expires: _.get(resp, 'data.at_expires'),
        token_type: BEARER_TOKEN_TYPE,
      }

      const userAuthData = jose.decodeJwt(resp.data.access_token)
      const expires_at = _.get(resp, 'data.at_expires') * 1000 // Unix Timestamp to Date.now() format

      const user = {
        email: userAuthData.email,
        name: userAuthData.fname,
        given_name: `${userAuthData.fname} ${userAuthData.lname}`,
        family_name: userAuthData.lname,
        image_url: userAuthData.picture,
        id: userAuthData.sub,
      }

      localStorage.setItem('userProfile', JSON.stringify(user))
      localStorage.setItem('tokenExpire', expires_at)
      return jwtToken
    })
    .catch(e => {
      if (!swallowError) {
        cleanUpLocalStorageAndRedirect()
        throw e
      }
    })
}

const cleanUpLocalStorageAndRedirect = () => {
  deleteJWT()
  localStorage.removeItem('userProfile')
  localStorage.removeItem('tokenExpire')

  if (!window.location.href.includes('login')) {
    window.location.href = '/#/login'
    window.location.reload()
  }
}

// returns Hapara JWT, not Google JWT
export const getToken = async ($window, $state, swallowError = false) => {
  if (isTestState) {
    return TEST_TOKEN
  }
  if (jwtToken) {
    const expired_at = _.get(jwtToken, 'at_expires', 0)
    const tokenExpires = moment.unix(parseInt(expired_at, 10))
    const closeToExpire = tokenExpires.isBefore(moment().add(1, 'minutes'))
    if (!closeToExpire) {
      return jwtToken
    }
  }
  if (!tokenUpdatePromise) {
    tokenUpdatePromise = refreshHaparaJWT(swallowError)
      .catch(e => {
        if (!swallowError) {
          $window.location = $state.href('anon.login')
          throw e
        }
      })
      .finally(() => {
        tokenUpdatePromise = null
      })
  }
  return await tokenUpdatePromise
}

export const invalidateRefreshToken = () => {
  const url = `${getHostUrl()}/auth-service/logout`
  const config = {
    withCredentials: true,
  }
  return axios.post(url, null, config)
}

export const deleteJWT = () => {
  jwtToken = null
  tokenUpdatePromise = null
}

export const setTestState = () => {
  isTestState = true
}

export const getUsageJWT = async (domain, site) => {
  // TODO - how to get school code in here?
  const haparaJWT = await getToken()
  const url = `${getHostUrl()}/auth-service/jwt/usage/exchange`
  const config = {
    headers: { Authorization: `${BEARER_TOKEN_TYPE} ${haparaJWT.access_token}` },
    withCredentials: true,
  }
  var resp = await axios.post(url, { domain, school_code: site }, config)
  return _.get(resp, 'data.access_token')
}
