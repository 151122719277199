const angular = require('angular')
const _ = require('lodash')

module.exports = angular
  .module('mw.delete-classes.directive', [
    require('hapara-ng/src/gainsight/gainsight'),
  ])
  .directive('deleteClasses', (
    $timeout,
    $stateParams,
    $state,
    PrefService,
    $uibModal,
    ExperimentsService,
    BigQueryService,
    $log
  ) => {
    return {
      restrict: 'E',
      template: require('./delete-classes.html'),
      controller: () => { },
      link(scope, element) {
        angular.extend(scope, {
          search: {
            all: true,
            domainURN: $stateParams.domain,
            page: 0,
            pageSize: 100,
            query: '',
            siteCode: $stateParams.nid || '',
          },
          deleted: {
            name: '',
          },
          searchResult: undefined,
          delayedCall: undefined,
          fetch: undefined,
          selected: undefined,
          status: undefined,
          school: '',
          data_loading_is_running: false,
          data_archiving_is_running: false,
          loading: true,

          /**
           * Get ClassObjects some from the list of all classes and filter that
           * @param {Array} classes
           */

          isDataLoadingRunning() {
            scope.loading = true
            const sort = { sord: 'desc', sidx: 'created_dt' }
            PrefService.get_tasks(1, 3, sort)
              .then(resp => {
                scope.data_loading_is_running =
                  _.find(resp.data.rows, function (task) {
                    return !task.is_completed && !task.has_failed
                  }) || false
              })
              .catch(() => {
                scope.data_loading_is_running = true
              })
              .finally(() => {
                $log.log(
                  'data_loading_is_running: ',
                  scope.data_loading_is_running
                )
                scope.loading = false
              })
          },
          isDataArchiving() {
            scope.loading = true
            return PrefService.is_archiving_running()
              .then(resp => {
                scope.data_archiving_is_running =
                  resp.data.archive_is_running.value
              })
              .catch(() => {
                scope.data_archiving_is_running = true
              })
              .finally(() => {
                scope.loading = false
                $log.log(
                  'data_archiving_is_running: ',
                  scope.data_archiving_is_running
                )
              })
          },
          getClassObjects(classes = []) {
            _.pullAllBy(scope.searchResult, classes, 'Group.Id')
            scope.searchResult = []

            _.each(classes, item => {
              // filtering for data loaded classes only
              if (
                item.Group.SourceInfo.Main[0] === 'Hap:HScript:cmds' ||
                item.Group.SourceInfo.Main[0] === 'Google:Directory'
              ) {
                const highlightedName = scope.getBoldRegular(item.Title)
                scope.searchResult.push({
                  nameRegularPrefix: highlightedName.inRegularPrefix,
                  nameBold: highlightedName.inBold,
                  nameRegularSuffix: highlightedName.inRegularSuffix,
                  name: item.Title,
                  id: item.Group.Id,
                  classId: item.Group.URN,
                  teacherNames: scope.getNamesOfTeachers(item.Managers),
                  teacherEmails: scope.getTeacherEmails(item.Managers),
                })
              }
            })
          },
          getTeacherEmails(arrTeachers) {
            // completeing teacher emails string
            let res = arrTeachers[0].PrimaryEmail

            _.each(arrTeachers, teacher => {
              if (teacher.PrimaryEmail.indexOf(scope.search.query) > -1) {
                res = teacher.PrimaryEmail
              }
            })
            if (arrTeachers.length == 2) {
              res += ' + 1 more teacher'
            } else if (arrTeachers.length > 2) {
              res += ` + ${arrTeachers.length - 1} more teachers`
            }
            return res
          },
          getNamesOfTeachers(arrTeachers) {
            let res = ''

            _.each(arrTeachers, teacher => {
              res += teacher.Fname + ' ' + teacher.Lname + ', '
            })

            if (res.length > 0) {
              res = res.substring(0, res.length - 2)
            }

            return res
          },
          getBoldRegular(str) {
            const clone = str.toLowerCase(),
              ind = clone.indexOf(scope.search.query.toLowerCase())
            let res
            if (ind > -1) {
              res = {
                inRegularPrefix: str.substr(0, ind),
                inBold: str.substr(ind, scope.search.query.length),
                inRegularSuffix: str.substring(
                  ind + scope.search.query.length,
                  str.length
                ),
              }
            } else {
              res = {
                inRegularPrefix: str,
                inBold: '',
                inRegularSuffix: '',
              }
            }
            return res
          },
          setFocusToInput() {
            scope.hideShowAppendix()
            document.getElementById('search-term-input').focus()
          },
          onSelectClass(ind) {
            scope.selected = scope.searchResult[ind]
            scope.search.query = scope.selected.name
            scope.searchResult = undefined
            scope.hideShowAppendix(false)
          },
          openModalToDelete() {
            if (scope.selected) {
              $uibModal.open({
                scope,
                template:
                  '<hac-delete-class-confirmator></hac-delete-class-confirmator>',
              })
            }
          },
          searchFor() {
            const search = _.clone(scope.search)
            scope.fetch = PrefService.query(search)

            return scope.fetch.$promise
              .then((result = {}) => {
                scope.searchResult = undefined
                scope.getClassObjects(result.Groups)
              })
              .catch(e => {
                scope.errored = e
              })
              .finally(() => {
                scope.fetch = undefined
              })
          },
          hideShowAppendix(hide = true) {
            const el = angular.element(
              element[0].getElementsByClassName('teacher-appendix')[0]
            )
            if (hide) {
              el.addClass('hidden-element') //not-displayed
            } else {
              el.removeClass('hidden-element')
            }
          },
          changeQueryHandler() {
            if (!scope.selected || scope.search.query !== scope.selected.name) {
              scope.selected = undefined
              scope.hideShowAppendix()
              if (scope.delayedCall) {
                $timeout.cancel(scope.delayedCall)
              }
              if (scope.search.query.length > 2) {
                scope.delayedCall = $timeout(scope.searchFor, 1000)
              }
            }
          },
          closeStatus() {
            scope.status = undefined
            scope.deleted.name = ''
          },
          deleteClass() {
            const deferred = PrefService.deleteClass({
              classes: [scope.selected.classId],
              domainurn: $stateParams.domain,
            })

            deferred.$promise
              .then(function () {
                scope.status = {
                  success: true,
                }
                scope.search.query = ''
                scope.deleted.name = scope.selected.name
                BigQueryService.send({
                  event_name: 'HAC:deleted.class',
                  dash_id: scope.selected.classId,
                  school_id: $stateParams.nid,
                })
                scope.selected = undefined
              })
              .catch(function () {
                scope.status = {
                  success: false,
                }
              })

            return deferred.$promise
          },
        })

        scope.isDataLoadingRunning()
        scope.isDataArchiving()
      },
    }
  }).name
