const angular = require('angular')

module.exports = angular
  .module('mw.analytics-index.directive', [])
  .directive('analyticsIndex', () => {
    return {
      restrict: 'E',
      scope: true,
      template: require('./analytics-index.html'),
    }
  }).name
