const angular = require('angular')
const _ = require('lodash')

module.exports = angular // eslint-disable-line angular/no-service-method
  .module('hap.pug-phone', [require('../environments/environment')])
  .service('PugPhone', (env, $window) => {
    const $PP = {
      /**
       * Dial the PugPhone to get the right URL based on the `env`
       *
       * You can override pugs location on non-production servers by appending ?pugs=http://localhost:8081 to the query
       *
       * @param {String} path
       */
      dial(path) {
        const suffixes = {
          test: '-test',
          production: '',
        }

        const suffix = _.has(suffixes, env) ? suffixes[env] : '-test'

        if ($window.location.search.substring(0, 6) === '?pugs=' && env !== 'production') {
          return decodeURIComponent($window.location.search.substring(6)) + path
        }
        if (env === 'local') {
          return `http://pugs:8089${path}`
        }
        return `https://pugs${suffix}.teacherdashboard.com${path}`
      },
      dialAdmin(path) {
        const suffixes = {
          test: '-test',
          production: '',
        }

        const suffix = _.has(suffixes, env) ? suffixes[env] : '-test'
        return `https://api${suffix}.hapara.com${path}`
      },
    }

    return $PP
  }).name
