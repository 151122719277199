const angular = require('angular')
const CONFIG = require('./config')
const { waitFor } = require('./util')

module.exports = angular
  .module('mw.base-states', [
    require('angular-ui-router'),
    require('./service'),
    require('hapara-ng/src/experiments/experiments.service'),
  ])
  .config(($stateProvider, $urlRouterProvider, DomainServiceProvider) => {
    $stateProvider
      .state('monkeywrench', {
        abstract: true,
        views: {
          '@': {
            template: '<div ui-view></div>',
          },
        },
        url: '',
      })
      .state('monkeywrench.login', {
        template: '<login/>',
        url: '/login?redir&domain&nid',
      })
      .state('monkeywrench.testlogin', {
        template: '<testlogin/>',
        url: '/dfg2ert25hgkj-test-login?redir&domain&nid',
      })
      .state('monkeywrench.forbidden', {
        template: '<forbidden/>',
        url: '/forbidden',
      })
      .state('monkeywrench.base', {
        abstract: true,
        params: {
          nid: {
            squash: true,
            value: '',
          },
        },
        resolve: {
          user(
            $q,
            $location,
            userFactoryGoogle,
            $state,
            $stateParams,
            DomainService,
            ExperimentsService,
            $localStorage,
            PrefService,
            $rootScope
          ) {
            /**
             * This is the base resolve for HAC
             * it needs to auth and connect to the feature flag service before it can do anything else.
             * So we can trust our feature flags will be air tight.
             */
            let deferred = $q.defer()
            $rootScope.doingResolve = true

            waitFor(() => window.google !== undefined)
              .then(() => {
                var { domain, nid, isFreshLogin } = $stateParams
                // If you don't know the domain check again what is in the url. We found a problem when loggin in and assigning permissions to google where it looses track of this.
                if (!domain) {
                  domain = $location.search().domain
                }
                DomainServiceProvider.set_domain_nid(domain, nid)
                $localStorage.domain_nid = {
                  domain,
                  nid,
                  isFreshLogin,
                }
                /**
                 * HAC-ng needs to get it's feature flags from td-admin.
                 * We can pass a custom url to the feature flag service.
                 * Flags we need to get get added to the url like &flags=foobar
                 * Unlike the regular endpoint td-admin seems to need you to ask for the flag to get it.
                 */
                const ffEndpoint = `${CONFIG.API_ROOT}/api?cmd=featureflags&domain=${domain}${
                  nid ? '&nid=' + nid : ''
                }&flags=this-value-is-ignored-but-required,HAP-10205-HAC-Embed-Usage-Reports,HAP-10369-usage-report-gen2`
                ExperimentsService.forUser([], ffEndpoint, true).then(() => {
                  PrefService.get('news').then(resp => {
                    $rootScope.mw = resp.data
                    $rootScope.doingResolve = false
                    deferred.resolve()
                  })
                })
              })
              .catch(() => {
                const { domain, nid } = $stateParams
                $state
                  .go('monkeywrench.login', {
                    redir: $state.current.name,
                    domain,
                    nid,
                  })
                  .then(() => {
                    $rootScope.doingResolve = false
                  })
                deferred.resolve()
              })

            return deferred.promise
          },
        },
        data: {
          access: 'user',
        },
        views: {
          '@': {
            template: require('./layout.html'),
            controller: 'BaseController',
          },
        },
        url: '/d/{domain}/{nid}?{isFreshLogin}',
      })
    $urlRouterProvider.when(/\/domain\//, function ($state) {
      $state.go('monkeywrench.base.home')
    })
    $urlRouterProvider.otherwise(function ($injector) {
      var localStorage = $injector.get('$localStorage')
      var state = $injector.get('$state')
      var stateParams = $injector.get('$stateParams')
      // try to restore domain& nid from localStorage
      if (localStorage.domain_nid && localStorage.domain_nid.domain) {
        state.go('monkeywrench.base.home.index', {
          domain: localStorage.domain_nid.domain,
          nid: localStorage.domain_nid.nid,
          isFreshLogin: localStorage.isFreshLogin,
        })
      } else if (stateParams.test) {
        // This is for returning a  single directive for backstop testing
        return
      } else {
        var win = $injector.get('$window')
        win.location.href = CONFIG.API_ROOT
      }
    })
  }).name
