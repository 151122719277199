const angular = require('angular')

module.exports = angular
  .module('mw.self-prov', [
    require('./store.service'),
    require('./header'),
    require('./detail'),
    require('./id'),
    require('./id-ou'),
    require('./id-csv'),
    require('./groups'),
    require('./ou-editor.directive'),

    'ngTagsInput',
  ])
  .config($stateProvider => {
    $stateProvider
      .state('monkeywrench.self-prov', {
        url: '/self-prov',
        template: require('./index.html'),
        controller: ($scope, $state) => {
          $scope.state = $state
        },
        onEnter: ($state, userFactoryGoogle) => {
          userFactoryGoogle
            .auth()
            .then(() => {
              $state.go('monkeywrench.self-prov.detail')
            })
            .catch(() => {
              $state.go('monkeywrench.login', {
                redir: 'monkeywrench.self-prov.detail',
              })
            })
        },
      })
      .state('monkeywrench.self-prov.detail', {
        url: '/detail',
        template: '<self-prov-detail/>',
      })
      .state('monkeywrench.self-prov.id', {
        url: '/id',
        abstract: true,
        template: '<div ui-view></div>',
      })
      .state('monkeywrench.self-prov.id.index', {
        url: '/index',
        template: '<self-prov-id/>',
      })
      .state('monkeywrench.self-prov.id.ou', {
        url: '/ou',
        template: '<self-prov-id-ou/>',
      })
      .state('monkeywrench.self-prov.id.csv', {
        url: '/csv',
        template: '<self-prov-id-csv/>',
      })
      .state('monkeywrench.self-prov.groups', {
        url: '/groups',
        template: '<self-prov-groups/>',
      })
  }).name
