const angular = require('angular')
const _ = require('lodash')

module.exports = angular
  .module('hap.url-service', [
    require('angular-ui-router'),
    require('../environments/environment'),
    require('../experiments/experiments.service'),
  ])
  .factory('HaparaUrlService', ($state, env, ExperimentsService) => {
    const $HUS = {
      /**
       * Get the domain for a given key & `env`. Returns `undefined` if not valid
       * @param {Object} key -- possible values are dashboard, workspace, highlights, hsuite
       */
      domain(key) {
        const isSingleHaparaDomainFFEnabled = ExperimentsService.get('HAP-11276-ws-subpath')
        const DOMAINS = isSingleHaparaDomainFFEnabled
          ? {
              development: {
                dashboard: 'https://app-test.hapara.com/classes/my',
                highlights: 'https://app-test.hapara.com/classes/my',
                workspace: 'https://app-test.hapara.com/ws',
                hsuite: 'https://app-test.hapara.com',
                myStudentDashboard: 'https://app-test.hapara.com/student',
                appMyStudentDashboard: 'https://app-test.hapara.com/student',
              },
              production: {
                dashboard: 'https://app.hapara.com/classes/my',
                highlights: 'https://app.hapara.com/classes/my',
                workspace: 'https://app.hapara.com/ws',
                hsuite: 'https://app.hapara.com',
                myStudentDashboard: 'https://app.hapara.com/student',
                appMyStudentDashboard: 'https://app.hapara.com/student',
              },
              test: {
                dashboard: 'https://app-test.hapara.com/classes/my',
                highlights: 'https://app-test.hapara.com/classes/my',
                workspace: 'https://app-test.hapara.com/ws',
                hsuite: 'https://app-test.hapara.com',
                myStudentDashboard: 'https://app-test.hapara.com/ws',
                appMyStudentDashboard: 'https://app-test.hapara.com/student',
              },
              local: {
                dashboard: 'https://app-test.hapara.com/classes/my',
                highlights: 'https://app-test.hapara.com/classes/my',
                workspace: 'https://app-test.hapara.com/ws',
                hsuite: 'https://app-test.hapara.com',
                myStudentDashboard: 'https://app-test.hapara.com/student',
                appMyStudentDashboard: 'https://app-test.hapara.com/student',
              },
            }
          : {
              development: {
                dashboard: 'https://hydra-test-dot-hapara-dashboard.appspot.com',
                highlights: 'https://highlights-dev.teacherdashboard.com',
                workspace: 'https://workspace-dev.teacherdashboard.com',
                hsuite: 'https://app-test.hapara.com',
                myStudentDashboard: 'https://workspace-test.teacherdashboard.com',
                appMyStudentDashboard: 'https://app-test.mystudentdashboard.com',
              },
              production: {
                dashboard: 'https://www.teacherdashboard.com',
                highlights: 'https://highlights.teacherdashboard.com',
                workspace: 'https://workspace.teacherdashboard.com',
                hsuite: 'https://app.hapara.com',
                myStudentDashboard: 'https://mystudentdashboard.com',
                appMyStudentDashboard: 'https://app.mystudentdashboard.com',
              },
              test: {
                dashboard: 'https://hydra-test-dot-hapara-dashboard.appspot.com',
                highlights: 'https://highlights-test.teacherdashboard.com',
                workspace: 'https://workspace-test.teacherdashboard.com',
                hsuite: 'https://app-test.hapara.com',
                myStudentDashboard: 'https://workspace-test.teacherdashboard.com',
                appMyStudentDashboard: 'https://app-test.mystudentdashboard.com',
              },
              local: {
                dashboard: 'http://dashboard:3000',
                highlights: 'https://highlights-test.teacherdashboard.com',
                workspace: 'https://workspace-test.teacherdashboard.com',
                hsuite: 'https://app-test.hapara.com',
                myStudentDashboard: 'https://workspace-test.teacherdashboard.com',
                appMyStudentDashboard: 'https://app-test.mystudentdashboard.com',
              },
            }        
        return _.get(DOMAINS, `${env}.${key}`, undefined)
      },
      /**
       * Get the url for a given state & item
       * @param {String} state
       * @param {Object} item
       */
      url(state, item) {
        const classId = _.get(item, 'classId')
        const groupName = _.get(item, 'viewgroupId', '').toLowerCase()

        // short circuit if we know about this state
        if ($state.get(state)) {
          return $state.href(state, item)
        }

        if (state === 'td.manage-classes') {
          // we keep 'td.manage-classes' condition for backwards compatibility
          return `${$HUS.domain('hsuite')}/classes/my`
        } else if (state === 'hsuite.manage-classes') {
          return `${$HUS.domain('hsuite')}/classes/my`
        } else if (state === 'td.class.info') {
          return `${$HUS.domain('dashboard')}/#/td/${classId}/edit/`
        } else if (state === 'td.class.index') {
          return `${$HUS.domain('dashboard')}/#/td/${classId}/`
        } else if (state === 'td.class.drive') {
          return `${$HUS.domain('dashboard')}/#/td/${classId}/${groupName}//`
        } else if (
          state === 'td.class.gmail' ||
          state === 'td.class.outlook' ||
          state === 'td.class.onedrive'
        ) {
          return `${$HUS.domain('dashboard')}/#/td/${classId}/${groupName}/`
        } else if (state === 'td.class.sharing') {
          return `${$HUS.domain('dashboard')}/#/td/${classId}/${groupName}/`
        } else if (state === 'boards') {
          // a state for old Workspace urls
          if (classId) {
            return `${$HUS.domain('workspace')}/#/workspace?classId=${classId}`
          } else {
            return `${$HUS.domain('workspace')}/#/workspace`
          }
        } else if (state === 'hsuite.boards') {
          // a state for new Workspace urls only
          if (classId) {
            return `${$HUS.domain('hsuite')}/workspace/myworkspaces/class?classId=${classId}`
          } else {
            return `${$HUS.domain('hsuite')}/workspace/myworkspaces/owned-by-me`
          }
        } else if (state === 'hsuite.professional-learning') {
          return `${$HUS.domain('hsuite')}/workspace/professional-learning`
        } else if (state === 'my.studentdashboard') {
          return `${$HUS.domain('myStudentDashboard')}/#/workspace`
        } else if (state === 'app.studentdashboard') {
          return `${$HUS.domain('appMyStudentDashboard')}`
        } else if (state === 'highlights.dashboard.activity') {
          return `${$HUS.domain('highlights')}/#/highlights/${classId}/activity`
        } else if (state === 'hsuite.highlights.dashboard.activity') {
          return `${$HUS.domain('hsuite')}/highlights/activity?classId=${classId}`
        } else if (state === 'smartshare.action') {
          return `${$HUS.domain('dashboard')}/#/td/${classId}/dashboard//?open=smartshare`
        } else {
          return undefined
        }
      },
    }

    return $HUS
  }).name
