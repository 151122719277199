const angular = require('angular')
module.exports = angular
  .module('mw.test-login.directive', [])
  .directive('testlogin', (userFactoryGoogle, $log, $state, $localStorage) => {
    function onAuth() {
      // we cannot access the feature flag service here so we pass isFreshLogin for HAP-5359_HACAppReauthorize
      $state.go('monkeywrench.base.home.index', {
        domain: $localStorage.testUser.email.split('@')[1],
        isFreshLogin: true,
      })
    }
    return {
      restrict: 'E',
      scope: false,
      link: function(scope) {
        angular.extend(scope, {
          login: function() {
            $log.info('Logging in', scope.email, scope.token)
            userFactoryGoogle.testLogin(scope.email, scope.token).then(onAuth)
          },
          userFactoryGoogle,
        })
      },
      template: require('./test-login.html'),
    }
  }).name
